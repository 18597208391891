import React, { useState, useEffect } from "react";
import APIServices from "../../../../api/API-Services";
import { API } from "../../../../api/API-Url";
import "./DataRowActionManager.scss";

const DataRowActionManager = ({ screenName }) => {
    const [dataRowActions, setDataRowActions] = useState([]); // Existing actions
    const [newActions, setNewActions] = useState([]); // Newly added actions
    const [deletedActionIds, setDeletedActionIds] = useState([]); // Actions to delete
    const [actionTypes, setActionTypes] = useState({
        PreAction: [],
        PostAction: [],
        OnSuccessAction: [],
        OnFailAction: [],
    });

    // Fetch action types based on category
    const fetchActionTypes = async (category) => {
        try {
            const response = await APIServices.GET(
                `${API.url}dynamicSearch/${screenName}/action-types?Category=${category}`
            );
            return response.data || [];
        } catch (error) {
            console.error(`Error fetching ${category} action types:`, error);
            return [];
        }
    };

    // Fetch all action types and existing data row actions
    useEffect(() => {
        const loadActionTypes = async () => {
            const preActionTypes = await fetchActionTypes("PreAction");
            const postActionTypes = await fetchActionTypes("PostAction");
            const onSuccessActionTypes = await fetchActionTypes("OnSuccessAction");
            const onFailActionTypes = await fetchActionTypes("OnFailAction");

            setActionTypes({
                PreAction: preActionTypes,
                PostAction: postActionTypes,
                OnSuccessAction: onSuccessActionTypes,
                OnFailAction: onFailActionTypes,
            });
        };

        const fetchDataRowActions = async () => {
            try {
                const response = await APIServices.GET(
                    `${API.url}dynamicSearch/${screenName}/getDataRowActions`
                );
                setDataRowActions(response.data || []);
            } catch (error) {
                console.error("Error fetching data row actions:", error);
            }
        };

        loadActionTypes();
        fetchDataRowActions();
    }, [screenName]);

    // Handle adding a new action
    const handleAddAction = () => {
        const newAction = {
            Id: null, // Null indicates this is a new action
            Name: "",
            Icon: "",
            Color: "#ffffff",
            PreActionTypeId: "",
            PreActionText: "",
            PostActionTypeId: "",
            PostActionText: "",
            OnSuccessActionTypeId: "",
            OnSuccessActionText: "",
            OnFailActionTypeId: "",
            OnFailActionText: "",
            RequiresApproval: false,
            IsEnabled: true,
        };

        setNewActions((prevNewActions) => [...prevNewActions, newAction]); // Add new action to state
    };

    // Handle deleting an action
    const handleDeleteAction = (id, isExisting) => {
        if (isExisting) {
            setDeletedActionIds([...deletedActionIds, id]);
            setDataRowActions(dataRowActions.filter((action) => action.id !== id));
        } else {
            setNewActions(newActions.filter((_, index) => index !== id));
        }
    };

    // Handle input changes for both new and existing actions
    const handleInputChange = (index, field, value, isNew) => {
        if (isNew) {
            const updatedNewActions = [...newActions];
            updatedNewActions[index][field] = value;
            setNewActions(updatedNewActions);
        } else {
            const updatedActions = [...dataRowActions];
            updatedActions[index][field] = value;
            setDataRowActions(updatedActions);
        }
    };

    // Save all changes (new, edited, and deleted actions)
    const handleSave = async () => {
        const payload = {
            screenName,
            newActions,
            updatedActions: dataRowActions,
            deletedActionIds,
        };

        try {
            await APIServices.POST(
                `${API.url}/SearchScreen/${screenName}/saveActions`,
                payload
            );
            alert("Actions saved successfully!");
            window.location.reload(); // Reload to reflect changes
        } catch (error) {
            console.error("Error saving actions:", error);
        }
    };

    return (
        <div>
            {/* Display Existing Actions */}
            {dataRowActions.map((action, index) => (
                <div key={action.id} className="action-card">
                    <div className="inline">
                        <h4>Action: {action.name || "New Action"}</h4>
                        <button
                            className="Delete"
                            onClick={() => handleDeleteAction(action.id, true)}
                        >
                            <i className="ri-delete-bin-5-fill"></i> Delete
                        </button>
                    </div>
                    <div>
                        <label>Name:</label>
                        <input
                            type="text"
                            value={action.name}
                            onChange={(e) => handleInputChange(index, "Name", e.target.value, false)}
                        />
                        <label>Icon:</label>
                        <input
                            type="text"
                            value={action.Icon}
                            onChange={(e) => handleInputChange(index, "Icon", e.target.value, false)}
                        />
                        <label>Color:</label>
                        <input
                            type="color"
                            value={action.Color}
                            onChange={(e) => handleInputChange(index, "Color", e.target.value, false)}
                        />
                        {/* Pre Action Dropdown */}
                        <label>Pre Action:</label>
                        <select
                            value={action.PreActionTypeId}
                            onChange={(e) => handleInputChange(index, "PreActionTypeId", e.target.value, false)}
                        >
                            <option value="">Select Pre Action</option>
                            {actionTypes.PreAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>Pre Action Text:</label>
                        <input
                            type="text"
                            value={action.PreActionText}
                            onChange={(e) => handleInputChange(index, "PreActionText", e.target.value, false)}
                        />
                        {/* Post Action Dropdown */}
                        <label>Post Action:</label>
                        <select
                            value={action.PostActionTypeId}
                            onChange={(e) => handleInputChange(index, "PostActionTypeId", e.target.value, false)}
                        >
                            <option value="">Select Post Action</option>
                            {actionTypes.PostAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>Post Action Text:</label>
                        <input
                            type="text"
                            value={action.PostActionText}
                            onChange={(e) => handleInputChange(index, "PostActionText", e.target.value, false)}
                        />
                        {/* On Success Action Dropdown */}
                        <label>On Success Action:</label>
                        <select
                            value={action.OnSuccessActionTypeId}
                            onChange={(e) => handleInputChange(index, "OnSuccessActionTypeId", e.target.value, false)}
                        >
                            <option value="">Select On Success Action</option>
                            {actionTypes.OnSuccessAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>On Success Action Text:</label>
                        <input
                            type="text"
                            value={action.OnSuccessActionText}
                            onChange={(e) => handleInputChange(index, "OnSuccessActionText", e.target.value, false)}
                        />
                        {/* On Fail Action Dropdown */}
                        <label>On Fail Action:</label>
                        <select
                            value={action.OnFailActionTypeId}
                            onChange={(e) => handleInputChange(index, "OnFailActionTypeId", e.target.value, false)}
                        >
                            <option value="">Select On Fail Action</option>
                            {actionTypes.OnFailAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>On Fail Action Text:</label>
                        <input
                            type="text"
                            value={action.OnFailActionText}
                            onChange={(e) => handleInputChange(index, "OnFailActionText", e.target.value, false)}
                        />
                    </div>
                </div>
            ))}

            {/* Display New Actions */}
            {newActions.map((action, index) => (
                <div key={index} className="action-card">
                    <div className="inline">
                        <h4>New Action</h4>
                        <button
                            className="Delete"
                            onClick={() => handleDeleteAction(index, false)}
                        >
                            <i className="ri-delete-bin-5-fill"></i> Delete
                        </button>
                    </div>
                    <div>
                        {/* Input fields for New Actions */}
                        <label>Name:</label>
                        <input
                            type="text"
                            value={action.Name}
                            onChange={(e) => handleInputChange(index, "Name", e.target.value, true)}
                        />
                        <label>Icon:</label>
                        <input
                            type="text"
                            value={action.Icon}
                            onChange={(e) => handleInputChange(index, "Icon", e.target.value, true)}
                        />
                        <label>Color:</label>
                        <input
                            type="color"
                            value={action.Color}
                            onChange={(e) => handleInputChange(index, "Color", e.target.value, true)}
                        />
                        {/* Pre Action Dropdown */}
                        <label>Pre Action:</label>
                        <select
                            value={action.PreActionTypeId}
                            onChange={(e) => handleInputChange(index, "PreActionTypeId", e.target.value, true)}
                        >
                            <option value="">Select Pre Action</option>
                            {actionTypes.PreAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>Pre Action Text:</label>
                        <input
                            type="text"
                            value={action.PreActionText}
                            onChange={(e) => handleInputChange(index, "PreActionText", e.target.value, true)}
                        />
                        {/* Post Action Dropdown */}
                        <label>Post Action:</label>
                        <select
                            value={action.PostActionTypeId}
                            onChange={(e) => handleInputChange(index, "PostActionTypeId", e.target.value, true)}
                        >
                            <option value="">Select Post Action</option>
                            {actionTypes.PostAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>Post Action Text:</label>
                        <input
                            type="text"
                            value={action.PostActionText}
                            onChange={(e) => handleInputChange(index, "PostActionText", e.target.value, true)}
                        />
                        {/* On Success Action Dropdown */}
                        <label>On Success Action:</label>
                        <select
                            value={action.OnSuccessActionTypeId}
                            onChange={(e) => handleInputChange(index, "OnSuccessActionTypeId", e.target.value, true)}
                        >
                            <option value="">Select On Success Action</option>
                            {actionTypes.OnSuccessAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>On Success Action Text:</label>
                        <input
                            type="text"
                            value={action.OnSuccessActionText}
                            onChange={(e) => handleInputChange(index, "OnSuccessActionText", e.target.value, true)}
                        />
                        {/* On Fail Action Dropdown */}
                        <label>On Fail Action:</label>
                        <select
                            value={action.OnFailActionTypeId}
                            onChange={(e) => handleInputChange(index, "OnFailActionTypeId", e.target.value, true)}
                        >
                            <option value="">Select On Fail Action</option>
                            {actionTypes.OnFailAction.map((type) => (
                                <option key={type.id} value={type.id}>
                                    {type.name}
                                </option>
                            ))}
                        </select>
                        <label>On Fail Action Text:</label>
                        <input
                            type="text"
                            value={action.OnFailActionText}
                            onChange={(e) => handleInputChange(index, "OnFailActionText", e.target.value, true)}
                        />
                    </div>
                </div>
            ))}

            {/* Add New Action Button */}
            <button onClick={handleAddAction}>
                <i className="ri-add-circle-fill"></i> Add New Action
            </button>

            {/* Save Button */}
            <button onClick={handleSave}>Save/Update</button>
        </div>
    );
};

export default DataRowActionManager;
