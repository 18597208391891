import React from "react";
import { Link } from "react-router-dom";

import "./ContactSection.scss";

import TextImg from "../../img/funtler-text-img.svg";
import Input from "../Input/Input";
import ThemeBtn from "../ThemeBtn/ThemeBtn";
import { useState } from "react";
import FetchService from "../../api/FetchService";

let request = {
  name: "",
  subject: "",
  body: "",
  email: "",
};

function ContactSection() {
  const [sending, setSending] = useState(false);
  const handleSubmit = async (e) => {
    setSending(true);
    e.preventDefault();

    request.name = name;
    request.email = email;
    request.body = message;
    request.subject = subject;

    FetchService.SendEmailContactUs(request).then((response) => {
      setSending(false);
      if (response) {
        alert("Din melding er sendt")
      } else {
        alert("Feil. Vær så snill, prøv på nytt")
      }
    });
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  return (
    <section className="Contact_main">
      {/* <marquee
        className="marquee_main marquee_main_top"
        direction="left"
        behavior="scroll"
        scrollamount="5"
      >
        <ul className="marquee_ul">
          <li className="marquee_li">
            <img className="marquee_img" src={TextImg} alt="img" />
          </li>
          <li className="marquee_li">
            <img className="marquee_img" src={TextImg} alt="img" />
          </li>
          <li className="marquee_li">
            <img className="marquee_img" src={TextImg} alt="img" />
          </li>
          <li className="marquee_li">
            <img className="marquee_img" src={TextImg} alt="img" />
          </li>
          <li className="marquee_li">
            <img className="marquee_img" src={TextImg} alt="img" />
          </li>
        </ul>
      </marquee> */}
      <div className="container">
        <div className="row row_custom">
          <div className="col-lg-6 col_content_otr">
            <div className="col_content_inr">
              {/*<h2 className="heading-h22 mb-5">*/}
              {/*Vi hører gjerne fra deg dersom du lurer på noe, har tilbakemeldinger eller noen gode partner ideer du ønsker å foreslå!*/}
              {/*</h2>*/}
              {/* <p className='heading-m desc'>
                                The sources of ideas for software products are plentiful. These ideas can come from market research.
                            </p> */}
              <ul className="icon_ul">
                <li className="icon_li">
                  <Link
                    to="mailto:support@funtlers.com"
                    className="icon_text_otr"
                  >
                    <div className="Icon_otr">
                      <i class="ri-mail-fill Icon"></i>
                    </div>
                    <p className="filter_heading text">support@funtlers.com</p>
                  </Link>
                </li>
                <li className="icon_li">
                                  <Link to="tel:+930 276 367" className="icon_text_otr">
                    <div className="Icon_otr">
                      <i class="ri-phone-fill Icon"></i>
                    </div>
                                      <p className="filter_heading text">930 276 367</p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col_form_otr">
            <form onSubmit={handleSubmit} className="col_form_inr">
              <div className="input_otr">
                <Input
                   InputClass="Theme_input_white filter_heading"
                  Inputype="name"
                  InputName="name"
                  InputPlaceholder="Navn"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              <div className="input_otr">
                <Input
                  InputClass="Theme_input_white filter_heading"
                  Inputype="email"
                  InputName="email"
                  InputPlaceholder="Epost"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <div className="input_otr">
                <Input
                  InputClass="Theme_input_white filter_heading"
                  Inputype="text"
                  InputName="text"
                  InputPlaceholder="Emne"
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />
              </div>
              <div className="input_otr">
                <textarea
                  className="Theme_input_white Text_area filter_heading"
                  placeholder="Melding"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
              </div>
              <div className="action_otr d-flex justify-content-center">
                {
                  !sending? <ThemeBtn
                   BtnClass="Theme_btn_primary send_btn "
                  BtnText="Send"
                /> : <div class="spinner-border text-center" role="status">
                  <span class="sr-only"></span>
                </div>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;