import React from 'react'
import ContactSection from '../ContactSection/ContactSection';
import InnerHeader from '../InnerHeader/InnerHeader';
import './ContactPage.scss';

function ContactPage() {
    return (

        <div data-testid="wrapper" class="_loading_overlay_wrapper css-79elbk">
  <div class="searchRsult_page">
    <div class="container">
      <div class="inner_header_main">
        <div class="header_wrapper">KONTAKT OSS</div>
      </div>
    </div>
                <div class="searchRsult_main">
                    <p class="heading filter_heading">
                        Ta gjerne kontakt med oss hvis du har sp&#248;rsm&#229;l, tilbakemeldinger eller spennende partnerforslag. Vi h&#248;rer gjerne fra deg!
                    </p>
      <div class="container">
        <div class="wrapper">
                         


          <div class="contact_info">
             <ContactSection />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



        //<div className='contact_page'>
        //    <InnerHeader
        //        HeaderHeading="Kontakt Oss"
        //        PageText="kontakt oss"
        //        hideHome={true}
        //    />
        //</div>
    )
}

export default ContactPage