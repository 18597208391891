import React, { useState, useMemo, useEffect } from "react";
import * as XLSX from "xlsx";
import "bootstrap/dist/css/bootstrap.min.css";

const MyBootstrapTable = ({
    data,
    formSchema,
    actions,
    handleAction,
    screenName,
    config = {
        showExportToCSV: true,
        showRowsPerPage: true,
        showPagination: true
    }
}) => {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState([]);

    // Update tableData when data prop changes
    useEffect(() => {
        setTableData([...data]);
    }, [data]); // Only update if data prop changes

    // Sort Handler
    const handleSort = (fieldName) => {
        const existingSort = sortConfig.find((config) => config.field === fieldName);
        let newSortConfig = [...sortConfig];

        if (existingSort) {
            existingSort.direction =
                existingSort.direction === "asc" ? "desc" : "asc";
        } else {
            newSortConfig.push({ field: fieldName, direction: "desc" });
        }

        setSortConfig(newSortConfig);

        const sorted = [...tableData].sort((a, b) => {
            for (const { field, direction } of newSortConfig) {
                const aValue = a[field] || "";
                const bValue = b[field] || "";
                const comparison =
                    typeof aValue === "string"
                        ? aValue.localeCompare(bValue)
                        : aValue - bValue;

                if (comparison !== 0) {
                    return direction === "asc" ? comparison : -comparison;
                }
            }
            return 0;
        });

        setTableData([...sorted]);
    };

    // Pagination Handler
    const paginatedData = useMemo(() => {
        const start = (currentPage - 1) * rowsPerPage;
        return tableData.slice(start, start + rowsPerPage);
    }, [tableData, currentPage, rowsPerPage]);

    const totalPages = Math.ceil(tableData.length / rowsPerPage);

    const handlePageChange = (page) => setCurrentPage(page);

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page
    };

    // Export Data as CSV
    //const exportToCSV = () => {
    //    // Get the header row using `formSchema` display names
    //    const headers = formSchema.map((field) => field.displayName);

    //    // Map the `tableData` rows to CSV-compatible rows using the `fieldName`
    //    const rows = tableData.map((row) =>
    //        formSchema.map((field) => {
    //            // Escape double quotes by doubling them in CSV format
    //            const value = row[field.fieldName] || ""; // Handle missing fields
    //            return `"${String(value).replace(/"/g, '""')}"`; // Escape quotes
    //        }).join(",") // Join all fields in a row
    //    );

    //    // Combine headers and rows, separated by newline characters
    //    const csvContent = [headers.join(","), ...rows].join("\n");

    //    // Create a Blob for the CSV data
    //    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    //    // Create a temporary download link
    //    const link = document.createElement("a");
    //    link.href = URL.createObjectURL(blob);
    //    link.setAttribute("download", "table_data.csv");
    //    document.body.appendChild(link);

    //    // Trigger the download and clean up
    //    link.click();
    //    document.body.removeChild(link);
    //};

    const exportToCSV = () => {
        if (!screenName || typeof screenName !== "string") {
            screenName = "Exported Data"; // Default name if screenName is invalid
        }

        // Prepare headers from formSchema (Field names displayed to user)
        const headers = formSchema.map((field) => field.displayName);

        // Prepare rows from tableData (actual data)
        const rows = tableData.map((row) =>
            formSchema.map((field) => row[field.fieldName] || "") // Extract the value for each field
        );

        // Combine headers and data into a format suitable for XLSX
        const worksheetData = [headers, ...rows];

        // Create the worksheet and workbook
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, screenName);

        // Apply styling (e.g., bold header row with theme color)
        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        const themeColor = "FC9765"; // Hex code for theme color

        // Apply styles to header row (bold, theme color)
        for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
            if (worksheet[cellAddress]) {
                worksheet[cellAddress].s = {
                    font: { bold: true, color: { rgb: "FFFFFF" } }, // White text color
                    fill: { fgColor: { rgb: themeColor } }, // Theme color applied to background
                    alignment: { horizontal: "center", vertical: "center" }, // Center alignment
                };
            }
        }

        // Add borders to all cells (header and data rows)
        for (let row = range.s.r; row <= range.e.r; row++) {
            for (let col = range.s.c; col <= range.e.c; col++) {
                const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                if (!worksheet[cellAddress]) worksheet[cellAddress] = { t: "s", v: "" }; // Ensure empty cells exist
                worksheet[cellAddress].s = worksheet[cellAddress].s || {};
                worksheet[cellAddress].s.border = {
                    top: { style: "thin", color: { rgb: "000000" } },
                    left: { style: "thin", color: { rgb: "000000" } },
                    bottom: { style: "thin", color: { rgb: "000000" } },
                    right: { style: "thin", color: { rgb: "000000" } },
                };
            }
        }

        // Export the Excel file with the name based on the search screen
        const fileName = `${screenName.replace(/[^a-zA-Z0-9]/g, "_")}_data.xlsx`;
        XLSX.writeFile(workbook, fileName);
    };

    // Select All Handler
    const handleSelectAll = (isChecked) => {
        const updatedData = tableData.map((row) => ({ ...row, isChecked }));
        setTableData([...updatedData]);
    };

    // Row Checkbox Handler
    const handleCheckboxChange = (rowId, isChecked) => {
        const updatedData = tableData.map((row) =>
            row.Id === rowId ? { ...row, isChecked } : row
        );
        setTableData([...updatedData]);
    };

    // Helper function for page range (including ellipsis)
    const getPaginationRange = () => {
        const range = [];
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (startPage > 1) {
            range.push(1);
            if (startPage > 2) range.push("...");
        }

        for (let i = startPage; i <= endPage; i++) {
            range.push(i);
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) range.push("...");
            range.push(totalPages);
        }

        return range;
    };

    return (
        <div className="container mt-3">
            {/* Controls */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                {config.showExportToCSV && (
                    <button className="btn btn-outline-primary" onClick={exportToCSV}>
                        <i className="ri-file-download-fill me-2"></i> Export to CSV
                    </button>
                )}
                {config.showRowsPerPage && (
                    <div className="d-flex align-items-center">
                        <span className="me-2">Rows per page:</span>
                        <select
                            className="form-select"
                            style={{ width: "100px" }}
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                        >
                            {[5, 10, 20, 50].map((num) => (
                                <option key={num} value={num}>
                                    {num}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>

            {/* Table */}
            <table className="table table-striped table-hover">
                <thead className="table-light">
                    <tr>
                        {/* Select All Checkbox */}
                        <th class="check-box">
                            <input
                                type="checkbox"
                                onChange={(e) => handleSelectAll(e.target.checked)}
                            />
                        </th>

                        {/* Dynamic Columns */}
                        {formSchema.map((field) => (
                            <th
                                key={field.id}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSort(field.fieldName)}
                            >
                                {field.displayName}
                                {sortConfig.some((config) => config.field === field.fieldName) ? (
                                    sortConfig.find((config) => config.field === field.fieldName)
                                        ?.direction === "asc" ? (
                                           <i className="ri ri-arrow-down-fill ms-2"></i>

                                    ) : (
                                            <i className="ri ri-arrow-up-fill ms-2"></i>

                                    )
                                ) : (
                                        <i className="ri ri-arrow-down-fill ms-2"></i>
                                )}
                            </th>
                        ))}

                        {/* Actions Column */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.length === 0 ? (
                        <tr>
                            <td colSpan={formSchema.length + 2} className="text-center">
                                No data available
                            </td>
                        </tr>
                    ) : (
                        paginatedData.map((row) => (
                            <tr key={row.Id}>
                                {/* Row Checkbox */}
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={row.isChecked || false}
                                        onChange={(e) =>
                                            handleCheckboxChange(row.Id, e.target.checked)
                                        }
                                    />
                                </td>

                                {/* Dynamic Columns */}
                                {formSchema.map((field) => (
                                    <td key={field.id}>
                                        <div
                                            className="text-truncate"
                                            title={(() => {
                                                const content =
                                                    field.fieldName === "ParentId" && row.ParentId
                                                        ? field.options.find((opt) => opt.value === row.ParentId)?.text || "N/A"
                                                        : row[field.fieldName] || "N/A";

                                                return content.length > 200 ? content : ""; // Tooltip for long content
                                            })()}
                                        >
                                            {field.fieldType === "html" ? (
                                                <div dangerouslySetInnerHTML={{ __html: row[field.fieldName] || "N/A" }} />
                                            ) : (
                                                field.fieldName === "ParentId" && row.ParentId
                                                    ? field.options.find((opt) => opt.value === row.ParentId)?.text || "N/A"
                                                    : row[field.fieldName] || "N/A"
                                            )}
                                        </div>
                                    </td>
                                ))}

                                {/* Action Buttons */}
                                <td>
                                    <div className="d-grid gap-2">
                                        {actions.map((action) => (
                                            <button
                                                key={action.id}
                                                className="btn btn-sm btn-outline-primary"
                                                onClick={() => handleAction(action, row)}
                                            >
                                                <i className={`ri ${action.icon} me-1`}></i>
                                                {action.displayName}
                                            </button>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>

            {/* Pagination Controls */}
            {config.showPagination && tableData.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <span>
                        {currentPage} of {totalPages} pages ({tableData.length} items)
                    </span>
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(1)}
                                >
                                    First
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    &lt;
                                </button>
                            </li>
                            {getPaginationRange().map((page, index) => (
                                <li
                                    key={index}
                                    className={`page-item ${page === currentPage ? "active" : ""}`}
                                >
                                    {page === "..." ? (
                                        <span className="page-link">...</span>
                                    ) : (
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(page)}
                                        >
                                            {page}
                                        </button>
                                    )}
                                </li>
                            ))}
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    &gt;
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(totalPages)}
                                >
                                    Last
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default MyBootstrapTable;
