import React, { useEffect, useState, useRef } from "react";
import InnerHeader from "../InnerHeader/InnerHeader";
import "./CampaignPage.scss";
import arrow from "../../img/down.png"
import visa from "../../img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg"
import mastercard from "../../img/mastercard-4d8844094130711885b5e41b28c9848f.svg"
import oneCard from "../../img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg"
import secondCard from "../../img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg"
import promoCode from "../../img/promo-code.png"
import checkMark from "../../img/check-mark.png"
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { Link, useLocation, useNavigate } from "react-router-dom";
import FetchService from "../../api/FetchService";
import { isLoggedIn, setBookingSession } from "../../api/NewLoginService";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination } from "swiper";
import { API } from "./../../api/API-Url";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga4";
import GuestPopup from "../GuestPopup/GuestPopup";

function CampaignPage({ setShowLogin, userType }) {
  const search = window.location.search;

  const params = new URLSearchParams(search);
  const activityIdQuery = params.get("id"); //
  const manualOrderQuery = params.get("isManualOrder"); //
  const type = params.get("type"); //

  if(manualOrderQuery) {
    import("./ManualOrder.scss")
  }
  let currentdate = new Date();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  //extends
  const [isVilkarVisible, setExtendsionVilkar] = useState(true)
  const [isStedVisible, setExtendsionSted] = useState(true)

  //getting activity id from previous screen

  const activityId = activityIdQuery ? activityIdQuery : location.state?.id;

  const [booking, setBooking] = useState({ currentdate });

  var [activity, setActivity] = useState({});
  const [desc, setDescription] = useState('');
  const [isDesc, setBoolDesc] = useState(false)
  const [actualDesc, setActualDesc] = useState('')

    const [peopleNumber, setPeopleNumber] = useState(0);
    const [childpeopleNumber, setChildPeopleNumber] = useState(0);

  const [images, setImages] = useState([]);

  const [guest, setGuest] = useState(false);
  const [guestEmail, setEmail] = useState("");

  const userObject = isLoggedIn()?.user;

  const [isTermsAccepted, setTerms] = useState(false);
  const termsReff = useRef(null);
  const [purchaseType, setPurchaseType] = useState("Simple");

  const [couponActivated, setCouponActivated] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [discountId, setDiscountId] = useState(0);
  const [PriceOption, setPriceOption] = useState('parent');

    const handleChildPriceChange = (event) => {
        setPriceOption(event.target.value);
    };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
    };


    const checkIsdiabled=()=>
    {
        debugger;
        let totalQty = childpeopleNumber + peopleNumber;
        return !(totalQty >= activity.minPerson && totalQty <= activity.maxPerson);
    }

    function SaveSession() {

        debugger;
     const totalAmount = activity.childOriginalPrice > 0
    ? (activity.price * peopleNumber) + (activity.childPrice * childpeopleNumber)
            : (activity.price * peopleNumber);

    const bookActivity = {
      activity: activity,
      peopleNumber: peopleNumber,
      childpeopleNumber: childpeopleNumber,
      total: totalAmount,
      user: userObject,
      priceOption: PriceOption,
      images: images?.length > 0 ? images[0] : null,
    };
    setBookingSession(bookActivity);
    }

    const redirectToCheckout = (type) => {
        if (!isTermsAccepted) return termsReff.current.focus();

        SaveSession();
        const redirectUrl = API.url +
            "create-checkout-session?PriceID=" +activity.stripePaymentID  +
            "&Quantity=" + peopleNumber +
            "&ActivityId=" +
            activityId +
            "&domain=" +
            window.location.origin +
            "&activityName=" + encodeURIComponent(activity.name) +
            "&type=" + type +
            "&nousedId=" + discountId +
            "&isManualOrder=true" +
            (activity.childStripePaymentID ? "&PriceID2=" + activity.childStripePaymentID : "") +
            (activity.childStripePaymentID ? "&Quantity2=" + childpeopleNumber : "");

        var form = document.createElement('form');
        form.action = redirectUrl;
        form.method = 'POST';
        document.body.appendChild(form); // Append the form to the document body
        form.submit();
    };

  const setActivityToSession = () => {
    sessionStorage.setItem("activityId", activity.id);
  };

  const removeActivityToSession = () => {
    sessionStorage.removeItem("activityId");
    sessionStorage.removeItem("guestEmail");
    };


    function generateActionUrl(PriceOption, activity, peopleNumber, activityId) {
        const apiUrl = API.url; // Replace with your actual API URL

        const PriceID = activity.stripePaymentID;

        return (
            apiUrl +
            "create-checkout-session?PriceID=" +
            PriceID +
            "&Quantity=" +peopleNumber  +
            "&ActivityId=" +
            activityId +
            "&domain=" +
            window.location.origin +
            (activity.childStripePaymentID ? "&PriceID2=" + activity.childStripePaymentID : "") +
            (activity.childStripePaymentID ? "&Quantity2=" + childpeopleNumber : "")

        );
    }

  const BookActivity = async () => {
    const bookActivity = {
      activity: activity,
      peopleNumber: peopleNumber + (activity.childOriginalPrice > 0 ?  childpeopleNumber : 0),
      user: userObject,
      };



      const totalAmount = activity.childOriginalPrice > 0
          ? (activity.price * peopleNumber) + (activity.childPrice * childpeopleNumber)
          : (activity.price * peopleNumber);

    var request = {
      userID: userObject.id,
        totalAmount: totalAmount,
      additionalDetails: "No details right now.",
      address: activity.city,
      createdDate: booking.currentdate,
      quantity: peopleNumber,
      activityId: activity.id,
      discountId: discountId,
    };

    var response = FetchService.BookAcitvity(request);

    response.then((data) => {
      console.log(data)
      if (data.data > 0) {
        ReactGA.event({
          action: "Checkout_Activity",
          category: "Payment",
          label: activity.name,
          value: request.totalAmount,
        });

        navigate("/bestillingsbekreftelse", { state: bookActivity });
      }
    });
  };

  useEffect(() => {
    //clearing activity id in session on refresh
    removeActivityToSession();

    const GetActivityById = () => {

      var response = FetchService.GetActivityById(activityId, userType);

      response.then((data) => {
          if (data) {
            if (data.data[0]?.childStripePaymentID) {

                // Assuming data.data is your JSON data
                const childPrice = data.data[0].childPrice;
                const childOriginalPrice = data.data[0].childOriginalPrice;

                // Calculate the discount percentage
                data.data[0].childdiscountPercentage = (((childOriginalPrice - childPrice) / childOriginalPrice) * 100).toFixed(0);

            }
          setActivity(data.data[0]);
          let description = data.data[0].description;
          if (description.length > 700 && !manualOrderQuery) {
            const trimmedDescription = description.slice(0, 700);
            const lastSpaceIndex = trimmedDescription.lastIndexOf(" ");
            description = trimmedDescription.slice(0, lastSpaceIndex);
            setDescription(description)
            setActualDesc(description)
              setBoolDesc(true)
          }else if(description.length > 300 && manualOrderQuery){
            const trimmedDescription = description.slice(0, 300);
            const lastSpaceIndex = trimmedDescription.lastIndexOf(" ");
            description = trimmedDescription.slice(0, lastSpaceIndex);
            setDescription(description)
            setActualDesc(description)
            setBoolDesc(true)
          } else {
            setActualDesc(data.data[0].description)
          }
          //  setPriceOption(data.data[0]?.childStripePaymentID ? 'parent' : 'child');
              setPeopleNumber(data.data[0]?.childStripePaymentID ? 0 : data.data[0]?.minPerson);
              setChildPeopleNumber(data.data[0]?.childStripePaymentID ? 0 : 0);

          setImages(JSON.parse(data.data[0]?.images));


          ReactGA.event({
            action: "Load_Activity",
            category: "Get Activity",
            label: activity.name,
            value: activity.name,
          });

          setIsLoading(false);
        }
      });
    };

    GetActivityById();
    window.scrollTo(0, 0);
  }, []);

  const terms = () =>{
    window.open("/vilkar", "_blank");
  }

  const BuyAsGuest = (type) =>{
    if(!isTermsAccepted) return termsReff.current.focus();
      setGuest(true)
      setPurchaseType(type);
  }

  const handleDesc = () =>{
    if(isDesc){
      setActualDesc(activity.description)
    }else{
      setActualDesc(desc)
    }
    setBoolDesc(!isDesc)
  }

  const handleEmail = (props) =>{
    setEmail(props.target.value)
  }

  const setSessionEmail = () =>{
    sessionStorage.setItem("guestEmail", guestEmail);
  }

  function ActualDescs() {
    return (
      <>
        <p style={{ textAlign: "justify", display: "inline-block", wordBreak:"break-word",hyphens:"auto" }}>
                <div dangerouslySetInnerHTML={{ __html: activity?.description }} />
{/*                {!isDesc ? (*/}
{/*  manualOrderQuery ? (*/}
{/*    activity?.description?.length > 300 ? (*/}
{/*      <p>*/}
{/*        <strong onClick={handleDesc} style={{ wordBreak: "keep-all", cursor: "pointer" }}>*/}
{/*          ... Vis mindre*/}
{/*        </strong>*/}
{/*      </p>*/}
{/*    ) : (*/}
{/*      ""*/}
{/*    )*/}
{/*  ) : (*/}
{/*    activity?.description?.length > 700 ? (*/}
{/*      <p>*/}
{/*        <strong onClick={handleDesc} style={{ wordBreak: "keep-all", cursor: "pointer" }}>*/}
{/*          ... Vis mindre*/}
{/*        </strong>*/}
{/*      </p>*/}
{/*    ) : (*/}
{/*      ""*/}
{/*    )*/}
{/*  )*/}
{/*) : (*/}
{/*  <strong onClick={handleDesc} style={{ wordBreak: "keep-all", cursor: "pointer" }}>*/}
{/*    ... Se mer*/}
{/*  </strong>*/}
{/*)}*/}

        </p>
      </>
      );
  }

  const extendVil = (props) =>{
    const section = props.target.dataset.section;
    if(section==="vilkar"){
      return setExtendsionVilkar(!isVilkarVisible)
    }
    return setExtendsionSted(!isStedVisible)
  }

  const handleTerms = () =>{
    setTerms(!isTermsAccepted);
  }

  const [errorCoupon, setErrorCoupon] = useState('');

  const handleCouponApplied = () => {
    if(couponActivated) return;
    var response =  FetchService.GetCouponCode(String(couponCode), parseInt(activityId))

    response.then((data)=>{
      if(parseInt(data.data.isValid)==1){
        setCouponActivated(true);
        setDiscountId(data.data.discountId)
         const discountAmount = (data.data.discount / 100) * activity.price;
         const discountAmountChild = (data.data.discount / 100) * activity.childPrice;

        return setActivity(prevActivity => ({
            ...prevActivity,
            price: prevActivity.price - discountAmount ,
            childPrice: prevActivity.childPrice - discountAmountChild
        }));
      }else if(data.data.isValid==0){
        setErrorCoupon('Kupong brukt eller utløpt');
      }else{
        setErrorCoupon('Kupongen er feil');
      }
      setTimeout(function(){
        setErrorCoupon('');
      }, 3000);
    })
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text="Behandler forespørselen din"
    >
          { /*<div className="campaign_Page">
{!manualOrderQuery && <InnerHeader HeaderHeading={activity.name} />}
        {guest? (
            <div>
              <div className="backdrop">
                <GuestPopup type={purchaseType} setShowLogin={setGuest} setEmail={handleEmail} email={guestEmail} setSessionEmail={setSessionEmail} activity={activity} activityId={activityId} peopleNumber={peopleNumber} discountId={discountId} price={activity.price} priceOption={PriceOption} childprice={activity.price} childpeopleNumber={childpeopleNumber} />
              </div>
            </div>
        ) : ""}
        <div className="campaign_main">
          <div className="mx-auto my-5" style={{width:manualOrderQuery? "100%" : "95%"}}>
              {activity.price != null && activity.price != null && (
                <>
                  <div className="col_content_otr">
                    <h1 className="fw-bold text-center">{activity.name}</h1>
                    <div className="infoActivity d-flex flex-column mx-auto">
                      <div className="d-flex justify-content-between">
                        <div className="column_activity d-flex flex-column column_image">
                          <Swiper
                              slidesPerView={1}
                              spaceBetween={24}
                              effect={"fade"}
                              pagination={{
                                clickable: true,
                              }}
                              autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                              modules={[EffectFade, Autoplay, Pagination]}
                              className="mySwiper hero_swiper campaign_swiper"
                          >
                            {images &&
                                images.map((image) => (
                                    <SwiperSlide>
                                      <div className="img_otr">
                                        <img
                                            className="campaign_img"
                                            src={image.imageURL.replace(
                                                "{baseApiURL}",
                                                API.apiURLs
                                            )}
                                            alt="img"
                                        />
                                      </div>
                                    </SwiperSlide>
                                ))}
                          </Swiper>
                        </div>
                                          <div className="column_activity">


                          <h4 className="text-uppercase font-weight-bold text-center">Om opplevelsen</h4>
                          <hr/>
                          <div className="wrap_rabat_with_text">
                            <ActualDescs />
                          </div>
                        </div>
                        <div className="column_activity justify-content-center">
                          <h4 className="text-center">PRIS</h4>
                        <hr />

                          {activity.price>0?(<>
                          <div className="d-flex justify-content-center flex-column-reverse align-items-center prisen_information" style={{fontSize: "21px"}}>


                            <div className="d-flex flex-column justify-content-center align-items-center" style={{ fontSize: "19px" }}>
                                                          {<div style={{ display: 'contents' }}>
                                                              {activity.discountPercent > 0 ? (
                                                                  <div className="rabat_round" style={activity?.childOriginalPrice > 0 ? { marginLeft: '10px' } : {}}>
                                                                      {"-" + activity.discountPercent + "%"}
                                                                  </div>) : (<></>)}
                                                              <p>{activity?.childOriginalPrice > 0 && <span>Voksen</span>} Før: {activity.discountPercent !== 0 ? (<s>{activity.originalPrice + " NOK"}</s>) : activity.originalPrice + " NOK"}</p>
                                                              {activity.discountPercent === 0 ? (<></>) : (
                                                                  <p>{activity?.childOriginalPrice > 0 && <span>Voksen</span>} Nå: {activity.price + " NOK"}</p>)}
                                                              <h3 className="text_heading heading-xsbb d-flex align-center justify-content-center">
                                                                  <div className="icon_text_otr d-flex">
                                                                      <div className="d-flex align-items-center">
                                                                          <i
                                                                              onClick={() =>
                                                                                  peopleNumber > activity.minPerson
                                                                                      ? setPeopleNumber(peopleNumber - 1)
                                                                                      : setPeopleNumber(peopleNumber)
                                                                              }
                                                                              className="ri-indeterminate-circle-fill q_icon"
                                                                          ></i>
                                                                          <p>{peopleNumber}</p>
                                                                          <i
                                                                              onClick={() =>
                                                                                  peopleNumber < activity.maxPerson
                                                                                      ? setPeopleNumber(1 + peopleNumber)
                                                                                      : setPeopleNumber(peopleNumber)
                                                                              }
                                                                              className="ri-add-circle-fill q_icon"
                                                                          ></i>
                                                                      </div>

                                                                      <span style={{ marginTop: "-2px", fontSize: "28px" }}>{activity.priceModel === "activity"
                                                                          ? "aktiviteter"
                                                                          : "personer"}</span>
                                                                  </div>

                                                              </h3>
                                                          </div>

                                                          }


                                                          {activity?.childOriginalPrice > 0 && <div style={{ display: 'contents' }}>
                                                              <br />
                                                              {activity.childdiscountPercentage > 0 ? (
                                                                  <div className="rabat_round" style={activity?.childOriginalPrice > 0 ? { marginLeft: '10px' } : {}}>
                                                                      {"-" + activity.childdiscountPercentage + "%"}
                                                                  </div>) : (<></>)}
                                                              <p>{activity?.childOriginalPrice > 0 && <span>Barn</span>} Før: {activity.childdiscountPercentage !== 0 ? (<s>{activity.childOriginalPrice + " NOK"}</s>) : activity.childOriginalPrice + " NOK"}</p>
                                                              {activity.childdiscountPercentage === 0 ? (<></>) : (
                                                                  <p>{activity?.childOriginalPrice > 0 && <span>Barn</span>} Nå: {activity.childPrice + " NOK"}</p>)}

                                                              <h3 className="text_heading heading-xsbb d-flex align-center justify-content-center">
                                                                  <div className="icon_text_otr d-flex">
                                                                      <div className="d-flex align-items-center">
                                                                          <i
                                                                              onClick={() =>
                                                                                  childpeopleNumber > activity.minPerson
                                                                                      ? setChildPeopleNumber(childpeopleNumber - 1)
                                                                                      : setChildPeopleNumber(childpeopleNumber)
                                                                              }
                                                                              className="ri-indeterminate-circle-fill q_icon"
                                                                          ></i>
                                                                          <p>{childpeopleNumber}</p>
                                                                          <i
                                                                              onClick={() =>
                                                                                  childpeopleNumber < activity.maxPerson
                                                                                      ? setChildPeopleNumber(1 + childpeopleNumber)
                                                                                      : setChildPeopleNumber(childpeopleNumber)
                                                                              }
                                                                              className="ri-add-circle-fill q_icon"
                                                                          ></i>
                                                                      </div>

                                                                      <span style={{ marginTop: "-2px", fontSize: "28px" }}>{activity.priceModel === "activity"
                                                                          ? "aktiviteter"
                                                                          : "personer"}</span>
                                                                  </div>

                                                              </h3>
                                                              <hr />

                                                          </div>}

                              </div>
                              </div>


                                <div className="discountSection">
                                <div className="d-flex mediaChange" style={{width: "100%", justifyContent: "center", alignItems: "center", gap: "15px", maxWidth: "423px", flexWrap:"wrap"}}>
                                    <div className="iconInput">
                                      <input type="text" value={couponCode} onChange={(event)=>{
                                        const code = event.target.value;
                                        return isNaN(code.charAt(code.length - 1))? setCouponCode(code) : ""
                                      }}  disabled={couponActivated} placeholder="Angi kode"/>
                                      <img src={couponActivated? checkMark : promoCode} alt="Promo Code" style={{
                                        width: couponActivated ? 25+'px' : '',
                                        top: couponActivated ? 6+'px' : 3+'px',
                                      }} />
                                    </div>
                                    <button className={`discountApply ${couponActivated? "disabled" : ""}`} style={{width: "190px", minWidth: "190px"}} onClick={handleCouponApplied} disabled={couponActivated}>LØS INN KODE</button>
                                  </div>
                                  {couponActivated? <h5>Koden er benyttet!</h5> : errorCoupon.length==0? "" : <h5 style={{color: "#F54040"}}>{errorCoupon}!</h5>}
                                </div>
                                              </>) : <h3 className="text-center">Gratis</h3>}
                                              {activity?.childStripePaymentID && <div>
                                                  <br /> <b>Velg pristype:</b><br />

                                                  
                                              </div>}
                                              <br />
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                      {activity.price>0? (
                        <div className="column_activity vilkar">
                          <h4 className="text-uppercase font-weight-bold text-center" style={{cursor:"pointer",userSelect:"none"}} onClick={extendVil} data-section="vilkar">Viktig informasjon <img src={arrow} alt="extends" className="extend arrow_vilkar" onClick={extendVil} data-section="vilkar" style={{transform:isVilkarVisible? "rotate(0deg)":"rotate(180deg)"}} /></h4>
                          <hr/>
                            <p className="heading-xsb campaign_text info-text" style={{display: isVilkarVisible? "none" : "block"}}>
                            {/* Pris per{" "}
                            {activity.priceModel === "activity"
                                ? "activity"
                                : "person"}{" "}
                            <b> {activity.price + " NOK"} </b>
                            {activity.priceModel === "activity"
                                ? " per aktivitet"
                                : " per person"}
                            <br /> 
                            Gyldig for:{" "}
                                                      <span>
                              {activity.minPerson} - {activity.maxPerson}
                            </span> {" "} Personer<br />
                            Aktivitet må bookes mellom:{" "}
                                                      <span>
                              {formatDate(activity.validPeriodStart)} -{" "}
                              {formatDate(activity.validPeriodEnd)}
                                                      </span>
                          </p>
                          <div className="heading-xsb campaign_text" style={{display: isVilkarVisible? "none" : "block"}}>
                            <div dangerouslySetInnerHTML={{ __html: activity?.terms }} />
                          </div>
                        </div>
                      ) : <></> }
                      {activity.price>0? (
                        <div className="column_activity">
                          <h4 className="text-uppercase font-weight-bold text-center" data-section="sted" style={{cursor:"pointer",userSelect:"none"}} onClick={extendVil}>Sted <img src={arrow} alt="extends" className="extend arrow_sted" onClick={extendVil} data-section="sted" style={{transform:isStedVisible? "rotate(0deg)":"rotate(180deg)"}} /></h4>
                          <hr/>
                          <p style={{display: isStedVisible? "none" : "block"}}>Adresse: {activity.partnerAddress}</p>
                          <p style={{display: isStedVisible? "none" : "block",textTransform:"capitalize"}}>By: {activity.city}</p>
                        </div> ) : ""}
                        {activity?.price>0? (
                        <div className="column_activity" style={{marginTop: activity.discountPercent>0? "-45px" : "0px"}} >
                                                  {userObject ? (
                                                      /* <form
                                                         action={
                                                           API.url +
                                                           "create-checkout-session?PriceID=" +
                                                           activity.stripePaymentID +
                                                           "&Quantity=" +
                                                           peopleNumber +
                                                           "&ActivityId=" +
                                                           activityId +
                                                           "&domain=" +
                                                           window.location.origin
                                                         }
                                                         method="POST"
                                                       >
                                                         <button
                                                           onClick={() => {
                                                             SaveSession();
                                                           }}
                                                           className="Theme_btn_primary"
                                                           type="submit"
                                                         >
                                                           Kjøp
                                                         </button>
                                                       </form> 
                           <form       action={window.location.url = generateActionUrl(PriceOption, activity, peopleNumber, activityId)}
                                  method="POST"
                                  className="d-flex flex-column justify-content-center"
                              >
                                <div className="d-flex">
                                  <input type="checkbox" id="termsAndConditions" name="termsAndConditions" value="terms" className="mx-1 form-check-input" required />
                                  <label htmlFor="termsAndConditions" className="mb-1 mx-0 form-check-label text-break">Bekreft våre <span className="terms_and_con" onClick={terms}>vilkår og betingelser</span></label><br />
                                </div>
                                  <hr /> {manualOrderQuery ? null : <br /> }
                            {manualOrderQuery ? (
                                <><div className="total_price wd-100 mb-2">
                                    <p className="text-center" style={{ fontSize: "24px", letterSpacing: "3px" }}>
                                       Total:<br /> {activity?.childOriginalPrice > 0 ? ((activity.price * peopleNumber) + (activity.childPrice * childpeopleNumber)).toFixed(2) :  (activity.price * peopleNumber).toFixed(2)} NOK

                                    </p>
                                </div>
                                <button
                                   onClick={(event) => {
                                    event.preventDefault(); // Prevent form submission

                                        redirectToCheckout(1);
                                    }}
                                    className="Theme_btn_primary"
                                    type="submit"
                                    style={{ margin: "0 auto", padding: "10px 20px", width: manualOrderQuery ? "230px" : "150px" }}
                                >
                                       Kjøp manuell ordre
                                                                  </button>
                                <br/>
                                <button
                                    onClick={(event) => {
                                        event.preventDefault(); // Prevent form submission

                                        redirectToCheckout(2);
                                    }}
                                    className="Theme_btn_primary"
                                    type="submit"
                                    style={{ margin: "0 auto", padding: "10px 20px", width: manualOrderQuery ? "230px" : "150px" }}
                                >
                                 Kjøp manuell gavekort
                                </button>

                                </>
                            ) : (<>
                                <div className="total_price wd-100 mb-2">
                                    <p className="text-center" style={{ fontSize: "24px", letterSpacing: "3px" }}>
                                       Total:<br /> {activity?.childOriginalPrice > 0 ? ((activity.price * peopleNumber) + (activity.childPrice * childpeopleNumber)).toFixed(2) :  (activity.price * peopleNumber).toFixed(2)} NOK

                                    </p>
                                </div>
                                <button
                                    onClick={() => {
                                        SaveSession();
                                    }}
                                    className="Theme_btn_primary"
                                    type="submit"
                                    style={{ margin: "0 auto", padding: "10px 20px", width: "150px" }}
                                >
                                    Kjøp
                                </button>
                            </>
                            )}

                              </form>
                          ) : (
                            <form
                                 /*action={    window.location.url = API.url +
                                     "create-checkout-session?PriceID=" +
                                     activity.stripePaymentID +
                                     "&Quantity=" +
                                     peopleNumber +
                                     "&ActivityId=" +
                                     activityId +
                                     "&domain=" +
                                     window.location.origin}
                                  method="POST"
                                  className="d-flex flex-column justify-content-center"
                              >
                                                              <hr />
                                <br/>
                                <div className="total_price wd-100 mb-2">
                                  <p className="text-center" style={{fontSize: "24px",letterSpacing: "3px"}}>
                                     Total: {activity?.childOriginalPrice > 0 ? ((activity.price * peopleNumber) + (activity.childPrice * childpeopleNumber)).toFixed(2) : (activity.price * peopleNumber).toFixed(2)} NOK
                                  </p>
                                </div>
                               <div className="d-flex mb-3 mx-auto">
                                 <input type="checkbox" id="termsAndConditions" name="termsAndConditions" value="terms" className="mx-1 form-check-input" required onClick={handleTerms}  />
                                 <label htmlFor="termsAndConditions" className="mb-1 mx-0 form-check-label text-break">Bekreft våre <span className="terms_and_con" onClick={terms}>vilkår og betingelser</span></label><br />
                               </div>

                                <button
                                    onClick={() => {
                                      SaveSession();
                                      // eslint-disable-next-line no-unused-expressions
                                      BuyAsGuest(1);
                                    }}
                                    className="Theme_btn_primary"
                                     style={{margin:"0 auto 0 auto",padding: "18px 122px", fontSize: "27px",textTransform:"uppercase"}}
                                    type="button"
                                >
                                  Kjøp
                                </button>
                                <button
                                    onClick={() => {
                                      SaveSession();
                                      // eslint-disable-next-line no-unused-expressions
                                      BuyAsGuest(2);
                                    }}
                                    className="Theme_btn_primary"
                                    style={{ margin: "10px auto 10px auto", padding: "18px 96px", fontSize: "23px", textTransform: "uppercase" }}
                                    type="button"
                                >
                                  Kjøp som gavekort
                                </button>
                                <div className="payments_options">
                                  <img src={visa} alt="visa" />
                                  <img src={mastercard} alt="mastercard" />
                                  <img src={secondCard} alt="secondCard" />
                                  <img src={oneCard} alt="oneCard" />
                                </div>

                              </form>

                            /*<button
                              className="Theme_btn_primary"
                              onClick={() => {
                                setShowLogin(true);
                                setActivityToSession();
                              }}
                            >
                              Logg inn for å bestille
                            </button>
                          )}

                  </div> ) : (
                            ""
                          )}
                </div>
              </div>
              {/*<div className="col_content_inr">
                <div className="content">
                  <p className="heading-xsb campaign_text">

                    {activity?.description}
                  </p>
                   <p className="heading-xsb campaign_text">
              Fangene på Fortet tilbyr 48 forskjellige celler med ulike
              oppgaver man skal løse sammen som et lag.
            </p>
            <p className="heading-xsb campaign_text">
              Inviter venner, familie eller kollegaer enten nok til et lag
              – eller flere lag og konkurrer mot hverandre!
            </p>
            <p className="heading-xsb campaign_text">
              Hos Fangene på Fortet kan du arrangere teambuilding som en
              spennende og morsom konkurranse blant de ansatte, eller
              arrangere utdrikningslag for den som skal drikkes ut. Det er
              behov for alle slags egenskaper og talenter: Fysikk,
              kunnskap og kreativitet.
            </p>
            <p className="heading-xsb campaign_text">
              Vi har oppgaver som passer for alle – uansett fysisk form og
              alder.
            </p>
            <p className="heading-xsb campaign_text">
              Vi anbefaler fra 3 – 5 deltakere per lag slik at man får
              mest ut av opplevelsen, men det er også mulig å bare være 2.
            </p>
                </div>
                <h3 className="heading-h3 heading_terms">Vilkår</h3>
                <div className="content">
                  <p className="heading-xsb campaign_text">
                    Pris per{" "}
                    {activity.priceModel === "activity"
                      ? "activity"
                      : "person"}{" "}
                    <b> {activity.price + " NOK"} </b>
                    {activity.priceModel === "activity"
                      ? " per aktivitet"
                      : " per person"}
                    <br />
                    Gyldig for:{" "}
                    <b>
                      {activity.minPerson} - {activity.maxPerson}
                    </b>{" "}
                    Personer
                  </p>
                  <p className="heading-xsb campaign_text">
                    Aktivitet må bookes mellom:{" "}
                    <b>
                      {formatDate(activity.validPeriodStart)} -{" "}
                      {formatDate(activity.validPeriodEnd)}
                    </b>
                  </p>
                  <p className="heading-xsb campaign_text">
                    {activity?.terms}
                  </p>

                   <p className="heading-xsb campaign_text">
              Bruk rabattkode ved booking
            </p>
            <p className="heading-xsb campaign_text">
              Aktivitet bookes direkte med Fangene på fortet
            </p>
            <p className="heading-xsb campaign_text">
              Dealer som ikke brukes grunnet for sen booking, sykdom,
              manglende oppmøte, avbestilling eller flytting mindre enn 24
              timer før bestilt time, refunderes ikke
            </p>
                </div>
              </div>
                  </div>
                  <div className="col-lg-6 col_img_otr">
                    {/*<div className="col_img_inr">


                      <div className="social_text_otr">
                        <ul className="text_ul">
                          <li className="text_li">
                            <p className="text_heading heading-xsbb">
                              Leverandør:<span>{activity.partnerName}</span>
                            </p>
                          </li>
                          <li className="text_li">
                            <p className="text_heading heading-xsbb">
                              Total kostnad:
                              <span>
                                {activity.price * peopleNumber + " NOK"}
                              </span>
                            </p>
                          </li>
                          <li className="text_li">
                            {activity.priceModel === "activity" ? (
                              <p className="text_heading heading-xsbb">
                                Pris per aktivitet:
                                <span>{activity.price + " NOK"}</span>
                              </p>
                            ) : (
                              <p className="text_heading heading-xsbb">
                                Pris per person:
                                <span>
                                  {activity.price + " NOK per person"}
                                </span>
                              </p>
                            )}
                          </li>
                          <li className="text_li">
                            <h3 className="text_heading heading-xsbb">
                              <div className="icon_text_otr">
                                <i
                                  onClick={() =>
                                    peopleNumber > activity.minPerson
                                      ? setPeopleNumber(peopleNumber - 1)
                                      : setPeopleNumber(peopleNumber)
                                  }
                                  className="ri-indeterminate-circle-fill q_icon"
                                ></i>
                                <p>{peopleNumber}</p>
                                <i
                                  onClick={() =>
                                    peopleNumber < activity.maxPerson
                                      ? setPeopleNumber(1 + peopleNumber)
                                      : setPeopleNumber(peopleNumber)
                                  }
                                  className="ri-add-circle-fill q_icon"
                                ></i>
                              </div>
                              {activity.priceModel === "activity"
                                ? "aktiviteter"
                                : "personer"}
                            </h3>
                          </li>
                           <li className="text_li">
                      <h3 className="text_heading heading-xsbb">
                        {location.state.description}
                      </h3>
                    </li>
                          <li className="text_li">
                            <h3 className="text_heading heading-xsbb">
                              Rabatt:{" "}
                              <span> {activity.discountPercent + "%"}</span>
                            </h3>
                          </li>
                          <li className="text_li">
                            <h3 className="text_heading heading-xsbb">
                              Spart beløp:
                              <span>
                                {(activity.originalPrice - activity.price) *
                                  peopleNumber +
                                  " NOK"}
                              </span>
                            </h3>
                          </li>
                          <li className="text_li">
                             <button
                        className="Theme_btn_primary"
                        onClick={() => {
                          if (!userObject) {
                            alert("Error: User not logged in");
                          } else {
                            BookActivity();
                          }
                        }}
                      >
                        Book
                      </button>
                            {activity.price > 0 && userType === "customer" && (
                              <section>
                                {userObject ? (
                                  /*<form
                                    action={
                                      API.url +
                                      "create-checkout-session?PriceID=" +
                                      activity.stripePaymentID +
                                      "&Quantity=" +
                                      peopleNumber +
                                      "&ActivityId=" +
                                      activityId +
                                      "&domain=" +
                                      window.location.origin
                                    }
                                    method="POST"
                                  >
                                    <button
                                      onClick={() => {
                                        SaveSession();
                                      }}
                                      className="Theme_btn_primary"
                                      type="submit"
                                    >
                                      Kjøp
                                    </button>
                                  </form>
                                    <form
                                        action={    window.location.url = API.url +
                                            "create-checkout-session?PriceID=" +
                                            activity.stripePaymentID +
                                            "&Quantity=" +
                                            peopleNumber +
                                            "&ActivityId=" +
                                            activityId +
                                            "&domain=" +
                                            window.location.origin}
                                        method="POST"
                                    >
                                      <div className="d-flex">
                                        <input type="checkbox" id="termsAndConditions" name="termsAndConditions" value="terms" className="mx-2 form-check-input" required />
                                        <label htmlFor="termsAndConditions" className="mb-4 mx-0 form-check-label d-flex text-break">Bekreft våre <a href="/terms" target="_blank" className="mx-1">vilkår og betingelser</a></label><br />
                                      </div>
                                      <button
                                          onClick={() => {
                                            SaveSession();
                                          }}
                                          className="Theme_btn_primary"
                                          type="submit"
                                      >
                                        Kjøp som gjest
                                      </button>
                                    </form>
                                ) : (
                                  /*<button
                                    className="Theme_btn_primary"
                                    onClick={() => {
                                      setShowLogin(true);
                                      setActivityToSession();
                                    }}
                                  >
                                    Logg inn for å bestille
                                  </button>
                                   <form
                                        action={    window.location.url = API.url +
                                            "create-checkout-session?PriceID=" +
                                            activity.stripePaymentID +
                                            "&Quantity=" +
                                            peopleNumber +
                                            "&ActivityId=" +
                                            activityId +
                                            "&domain=" +
                                            window.location.origin}
                                        method="POST"
                                    >
                                     <div className="d-flex">
                                       <input type="checkbox" id="termsAndConditions" name="termsAndConditions" value="terms" className="mx-2 form-check-input" required />
                                       <label htmlFor="termsAndConditions" className="mb-4 mx-0 form-check-label d-flex text-break">Bekreft våre <a href="/terms" target="_blank" className="mx-1">vilkår og betingelser</a></label><br />
                                     </div>
                                      <button
                                          onClick={() => {
                                            SaveSession();
                                          }}
                                          className="Theme_btn_primary"
                                          type="submit"
                                      >
                                        Kjøp som gjest
                                      </button>
                                    </form>
                                )}
                              </section>

                          </li>
                        </ul>

                         Todo: here we can put some more information
                        <ul className="social_ul">
                          {activity.facebook && activity.facebook !== "" && (
                            <li className="social_li">
                              <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={activity.facebook}
                                className="social_a"
                              >
                                <i className="ri-facebook-circle-fill social_icon"></i>
                              </a>
                            </li>
                          )}

                          {activity.instagram && activity.instagram !== "" && (
                            <li className="social_li">
                              <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={activity.instagram}
                                className="social_a"
                              >
                                <i className="ri-instagram-fill social_icon"></i>
                              </a>
                            </li>
                          )}
                          {activity.youtube && activity.youtube !== "" && (
                            <li className="social_li">
                              <a
                                target={"_blank"}
                                rel="noreferrer"
                                href={activity.youtube}
                                className="social_a"
                              >
                                <i className="ri-youtube-fill social_icon"></i>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          </div> */}
          <section class="activity-section">
              {activity && activity.priceModel && (<div class="container-lg">
                  <div class="content-block">
                      <div class="info-block">
                          { <p class="title">{activity.name}</p>}
                          {guest ? (
                              <div>
                                  <div className="backdrop">
                                      <GuestPopup type={purchaseType} setShowLogin={setGuest} setEmail={handleEmail} email={guestEmail} setSessionEmail={setSessionEmail} activity={activity} activityId={activityId} peopleNumber={peopleNumber} discountId={discountId} price={activity.price} priceOption={PriceOption} childprice={activity.price} childpeopleNumber={childpeopleNumber} />
                                  </div>
                              </div>
                          ) : ""}
                          <ul class="nav nav-tabs nav-fill" id="infoTab" role="tablist">
                              <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="Omopplevelsen-tab" data-bs-toggle="tab" data-bs-target="#Omopplevelsen-tab-pane" type="button" role="tab" aria-controls="Omopplevelsen-tab-pane" aria-selected="true">Om opplevelsen</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="viktigInformasjon-tab" data-bs-toggle="tab" data-bs-target="#viktigInformasjon-tab-pane" type="button" role="tab" aria-controls="viktigInformasjon-tab-pane" aria-selected="false" tabindex="-1">Viktig informasjon</button>
                              </li>
                              <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="sted-tab" data-bs-toggle="tab" data-bs-target="#sted-tab-pane" type="button" role="tab" aria-controls="sted-tab-pane" aria-selected="false" tabindex="-1">Sted</button>
                              </li>
                          </ul>
                          <div class="tab-content" id="infoTabContent">
                              <div class="tab-pane fade active show" id="Omopplevelsen-tab-pane" role="tabpanel" aria-labelledby="Omopplevelsen-tab" tabindex="0">
                                  <div class="tab-body">
                                      <ActualDescs />
                                  </div>
                              </div>
                              <div class="tab-pane fade" id="viktigInformasjon-tab-pane" role="tabpanel" aria-labelledby="viktigInformasjon-tab" tabindex="0">
                                  <div class="tab-body">
                                      {activity.price > 0 ? (
                                          <div className="column_activity vilkar">
                                              <p className="">
                                                  {/* Pris per{" "}
                            {activity.priceModel === "activity"
                                ? "activity"
                                : "person"}{" "}
                            <b> {activity.price + " NOK"} </b>
                            {activity.priceModel === "activity"
                                ? " per aktivitet"
                                : " per person"}
                            <br /> */}
                                                  <strong>Gyldig for</strong>:{" "}
                                                  <span>
                                                      {activity.minPerson} - {activity.maxPerson}
                                                  </span>{" "} Personer
                                              {/*    <br />*/}
                                              {/*    <strong>Aktivitet må bookes mellom</strong>:{" "}*/}
                                              {/*    <span>*/}
                                              {/*        {formatDate(activity.validPeriodStart)} -{" "}*/}
                                              {/*        {formatDate(activity.validPeriodEnd)}*/}
                                              {/*    </span>*/}
                                              </p>
                                              <p><br/></p>
                                              <div className="">
                                                  <div dangerouslySetInnerHTML={{ __html: activity?.terms }} />
                                              </div>
                                          </div>
                                      ) : <></>}

                                  </div>
                              </div>
                              <div class="tab-pane fade" id="sted-tab-pane" role="tabpanel" aria-labelledby="sted-tab" tabindex="0">
                                  <div class="tab-body">
                                      {activity.price > 0 ? (
                                          <div className="column_activity">
                                              <p ><strong>Adresse</strong>: <span>{activity.partnerAddress}</span></p>
                                              <p style={{ textTransform: "capitalize" }}><strong>By</strong>: <span>{activity.city}</span></p>
                                          </div>) : ""}
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="payment-block">
                          <div class="banner">
                              <div class="img-container">
                                  <Swiper
                                      slidesPerView={1}
                                      spaceBetween={24}
                                      effect={"fade"}
                                      pagination={{
                                          clickable: true,
                                      }}
                                      autoplay={{
                                          delay: 2500,
                                          disableOnInteraction: false,
                                      }}
                                      modules={[EffectFade, Autoplay, Pagination]}
                                      className="mySwiper hero_swiper campaign_swiper"
                                  >
                                      {images &&
                                          images.map((image) => (
                                              <SwiperSlide>
                                                  <div className="img_otr">
                                                      <img
                                                          className="campaign_img"
                                                          src={image.imageURL.replace(
                                                              "{baseApiURL}",
                                                              "https://www.funtlers.com/"
                                                          )}
                                                          alt="img"
                                                      />
                                                  </div>
                                              </SwiperSlide>
                                          ))}
                                  </Swiper>
                                  <div class="discount-block">
                                      {" - " +
                                          (activity?.childOriginalPrice > 0
                                              ? Math.abs(
                                                  (
                                                      ((activity?.price || 0) + (activity?.childPrice || 0)) /
                                                      ((activity?.originalPrice || 1) + (activity?.childOriginalPrice || 1)) -
                                                      1
                                                  ) *
                                                  100
                                              ).toFixed(0) + " %"
                                              : Math.abs(activity?.discountPercent || 0).toFixed(0) + " %")}
                                  </div>

                              </div>
                          </div>
                          <div class="booking-card">
                              <ul class="list-group">
                                  <li class="list-group-item">
                                      <div class="info">
                                          <div class="lbl">{activity?.childOriginalPrice > 0 && <span>Voksen</span>} Pris</div>
                                          <div class="text price-block">
                                              <div class="price discount">{activity.discountPercent !== 0 ? (<>{activity.originalPrice + " NOK"}</>) : activity.originalPrice + " NOK"}</div>
                                              <div class="price">{activity.price + " NOK"}</div>
                                          </div>
                                      </div>
                                  </li>

                                  {type != 'B2B' && <li class="list-group-item">
                                      <div class="info">
                                          <div class="lbl">{activity?.childOriginalPrice > 0 ? <span>Antall Voksen</span> : <span>{(activity.priceModel === "time" ? "Antall timer" : activity.priceModel === "person"
                                              ? "Antall person"
                                              : "Antall aktivitet")}</span>} </div>
                                          <div class="text input-block">
                                              <div class="input-group">
                                                  <button class="btn" type="button" onClick={() => {
                                                      const canDecrement = activity.childStripePaymentID ? peopleNumber != 0 : peopleNumber!=activity.minPerson;
                                                         setPeopleNumber(canDecrement ? peopleNumber - 1 : peopleNumber);
                                                        }
                                                  }>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="2" viewBox="0 0 24 2">
                                                          <g fill="#fff" stroke="#707070" stroke-width="1" opacity="0.45">
                                                              <rect width="24" height="2" stroke="none"></rect>
                                                              <rect x="0.5" y="0.5" width="23" height="1" fill="none"></rect>
                                                          </g>
                                                      </svg>
                                                  </button>
                                                  <input type="text" class="form-control bg-transparent" aria-describedby="button-addon" value={peopleNumber} />
                                                  <button
                                                      className="btn"
                                                      type="button"
                                                      onClick={() => {
                                                          debugger;
                                                          const totalPeople = childpeopleNumber + peopleNumber;

                                                          // Allow increment only if total people are less than the maximum
                                                          // and either total is below the minimum or it satisfies the other conditions
                                                          const canIncrement = totalPeople < activity.maxPerson; // Special condition for initial addition

                                                          setPeopleNumber(canIncrement ? peopleNumber + 1 : peopleNumber);
                                                      }}
                                                  >
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                                          <g transform="translate(-1475.5 -614.076)">
                                                              <rect width="17" height="2" transform="translate(1475.5 621.076)" fill="#707070" opacity="0.45"></rect>
                                                              <rect width="17" height="2" transform="translate(1485.5 614.076) rotate(90)" fill="#707070" opacity="0.45"></rect>
                                                          </g>
                                                      </svg>
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </li>}

                                  {activity?.childOriginalPrice > 0 && (<><li class="list-group-item">
                                      <div class="info">
                                          <div class="lbl">{activity?.childOriginalPrice > 0 && <span>Barn</span>} Pris</div>
                                          <div class="text price-block">
                                              <div class="price discount">{activity.childOriginalPrice + " NOK"}</div>
                                              <div class="price">{activity.childPrice + " NOK"}</div>
                                          </div>
                                      </div>
                                  </li>

                                      {type!='B2B' && <li class="list-group-item">
                                          <div class="info">
                                              <div class="lbl">{activity?.childOriginalPrice > 0 ? <span>Antall Barn</span> : <span> {(activity.priceModel === "time" ? "Antall timer" : activity.priceModel === "person"
                                                  ? "Antall person"
                                                  : "Antall aktivitet")}</span>} </div>
                                              <div class="text input-block">
                                                  <div class="input-group">
                                                      <button class="btn" type="button" onClick={() => {
                                                          const canDecrement = childpeopleNumber != 0;
                                                          setChildPeopleNumber(canDecrement ? childpeopleNumber - 1 : childpeopleNumber);
                                                      }
                                                      }>
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="2" viewBox="0 0 24 2">
                                                              <g fill="#fff" stroke="#707070" stroke-width="1" opacity="0.45">
                                                                  <rect width="24" height="2" stroke="none"></rect>
                                                                  <rect x="0.5" y="0.5" width="23" height="1" fill="none"></rect>
                                                              </g>
                                                          </svg>
                                                      </button>
                                                      <input type="text" class="form-control bg-transparent" aria-describedby="button-addon" value={childpeopleNumber} />
                                                      <button className="btn" type="button" onClick={() => {
                                                          const totalPeople = childpeopleNumber + peopleNumber;

                                                          // Allow increment only if total people are less than the maximum
                                                          // and either total is below the minimum or it satisfies the other conditions
                                                          const canIncrement = totalPeople < activity.maxPerson ; // Special condition for initial addition

                                                          setChildPeopleNumber(canIncrement ? childpeopleNumber + 1 : childpeopleNumber);
                                                      }}>
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                                              <g transform="translate(-1475.5 -614.076)">
                                                                  <rect width="17" height="2" transform="translate(1475.5 621.076)" fill="#707070" opacity="0.45"></rect>
                                                                  <rect width="17" height="2" transform="translate(1485.5 614.076) rotate(90)" fill="#707070" opacity="0.45"></rect>
                                                              </g>
                                                          </svg>
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </li>}
                                  </>
                                  )}
                                  {type != 'B2B' && <li class="list-group-item">
                                      <div class="info">
                                          <div class="lbl">Innløse kode</div>
                                          <div class="text input-block iconInput">
                                              <input class="form-control" type="text" value={couponCode} onChange={(event) => {
                                                  const code = event.target.value;
                                                  const lastCharacter = code.charAt(code.length - 1);

                                                  return setCouponCode(code);
                                              }} disabled={couponActivated} placeholder="Angi kode" />
                                              <img src={couponActivated ? checkMark : promoCode} alt="Promo Code" style={{
                                                  width: couponActivated ? 25 + 'px' : '',
                                                  top: couponActivated ? 6 + 'px' : 3 + 'px',
                                              }} />


                                              <div class="">
                                                  <button className={`btn btn-primary ${couponActivated ? "disabled" : ""}`} style={{ borderRadius: "0px", marginTop: "5px", width: "147px", minWidth: "147px" }} onClick={handleCouponApplied} disabled={couponActivated}>LØS INN KODE</button>
                                                  {couponActivated ? <h5>Koden er benyttet!</h5> : errorCoupon.length == 0 ? "" : <h5 style={{ color: "#F54040" }}>{errorCoupon}!</h5>}

                                              </div>

                                          </div>

                                      </div>

                                  </li>}
                                  {type != 'B2B' && <li class="list-group-item">
                                      <div class="info">
                                          <div class="lbl">
                                              <strong>Total:</strong>
                                          </div>
                                          <div class="text price-block">
                                              <div class="price">
                                                  <strong>{activity?.childOriginalPrice > 0 ? ((activity.price * peopleNumber) + (activity.childPrice * childpeopleNumber)).toFixed(2) : (activity.price * peopleNumber).toFixed(2)} NOK</strong>
                                              </div>
                                          </div>
                                      </div>
                                      {checkIsdiabled() && <div style={{ color: "#F54040" }}>*Merk: Det totale antallet tillatte personer er mellom {activity.minPerson} og {activity.maxPerson} personer.</div>}
                                      <div class="btn-container">

                                          {manualOrderQuery ? (
                                              <>
                                                  <button
                                                      onClick={(event) => {
                                                          event.preventDefault(); // Prevent form submission

                                                          redirectToCheckout(1);
                                                      }}
                                                      disabled={checkIsdiabled()} 
                                                      className="btn btn-primary"
                                                      type="submit"
                                                  >
                                                      Kjøp ordre
                                                  </button>
                                                  <button
                                                      onClick={(event) => {
                                                          event.preventDefault(); // Prevent form submission

                                                          redirectToCheckout(2);
                                                      }}
                                                      disabled={checkIsdiabled()} 
                                                      className="btn btn-som"
                                                      type="submit"
                                                  >
                                                      Kjøp gavekort
                                                  </button>

                                              </>
                                          ) : (<>
                                                  <button type="button" disabled={checkIsdiabled()}  class="btn btn-primary"    onClick={() => {
                                                  SaveSession();
                                                  // eslint-disable-next-line no-unused-expressions
                                                  BuyAsGuest(1);
                                              }}>KJØP</button>
                                                  <button type="button" disabled={checkIsdiabled()}  class="btn btn-som" onClick={() => {
                                                  SaveSession();
                                                  // eslint-disable-next-line no-unused-expressions
                                                  BuyAsGuest(2);
                                              }}>KJØP SOM GAVEKORT</button>
                                          </>
                                          )}




                                      </div>

                                      <div class="form-check">
                                          <input disabled={checkIsdiabled()}   class="form-check-input" type="checkbox" id="flexCheck" required onClick={handleTerms} ref={termsReff} />
                                          <label htmlFor="termsAndConditions" className="mb-1 mx-0 form-check-label text-break">Bekreft våre &nbsp;<a className="terms_and_con" onClick={terms}>vilkår og betingelser</a></label>
                                      </div>
                                      <div class="payment-getway">
                                          <div class="img-container">
                                              <img src={visa} alt="visa" />
                                              <img src={mastercard} alt="mastercard" />
                                              <img src={secondCard} alt="secondCard" />
                                              <img src={oneCard} alt="oneCard" />
                                          </div>
                                      </div>
                                  </li>}

                                  {type == 'B2B' && <div class="info info-block new-info">
                                      Her finner du detaljer om aktiviteten og prisen per person. For å inkludere denne aktiviteten må du tilbake til verktøyet, og dra den inn i sirkelen. Der får du velge antall personer du ønsker å ta med på aktiviteten. Vilkår godkjennes ved å inkludere denne.

                                  </div>}
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>)}
                  </section>

    </LoadingOverlay>
  );
}

export default CampaignPage;
