import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation in React Router v6
import FetchService from '../../api/FetchService';
import './UtilityDashboard.css';

const UtilityDashboard = () => {
    const [utilities, setUtilities] = useState([]);
    const [filteredUtilities, setFilteredUtilities] = useState([]); // To store filtered utilities
    const [loading, setLoading] = useState(false);
    const [parentUtility, setParentUtility] = useState(null);  // For parent utility (back button control)
    const [selectedUtility, setSelectedUtility] = useState(null); // For utility with route
    const [iframeLoading, setIframeLoading] = useState(true); // New state to track iframe loading
    const [iframeUrl, setIframeUrl] = useState(null); // To track the iframe's URL and handle redirection
    const [searchQuery, setSearchQuery] = useState(''); // State to track the search query
    const navigate = useNavigate();  // For navigation with useNavigate

    useEffect(() => {
        fetchUtilities(); // Fetch utilities on initial load
    }, []);

    // Fetch utility data from the API
    const fetchUtilities = async () => {
        try {
            setLoading(true);
            const response = await FetchService.GetUtilities();
            setUtilities(response.data);
            setFilteredUtilities(response.data); // Initially set filtered utilities as all utilities
        } catch (error) {
            console.error('Error fetching utilities:', error);
        } finally {
            setLoading(false);
        }
    };

    // Filter utilities based on search query
    const handleSearch = () => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filtered = utilities.filter(
            (utility) =>
                utility.name.toLowerCase().includes(lowerCaseQuery) ||
                utility.description.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredUtilities(filtered); // Update the filtered utilities
    };

    // Handle utility click: either navigate to its route or show child utilities
    const handleUtilityClick = (utility) => {
        if (utility.route) {
            // If the utility has a route, open it in an iframe
            setSelectedUtility(utility);  // Set selected utility for iframe display
            setParentUtility(null);  // Clear parent utility if a route is clicked
            setIframeLoading(true); // Set iframe loading to true when a new utility is selected
            setIframeUrl(utility.route); // Store the iframe URL to track redirection
        } else {
            // If the utility is a parent (no route), expand or collapse the utility
            setParentUtility(utility);  // Set current parent utility
            setLoading(true);  // Start loading
            setUtilities([]);  // Clear the current list

            // Simulate an API call to fetch children of the parent utility
            setTimeout(() => {
                const childUtilities = utilities.filter((ut) => ut.parentId === utility.id);
                setUtilities(childUtilities);  // Set the child utilities
                setLoading(false);  // Stop loading after fetching
            }, 1000);  // Simulate a delay for the loading state
        }
    };

    // Back button functionality to navigate back to top-level utilities
    const handleBackButton = () => {
        if (selectedUtility) {
            // If viewing an iframe, return to utilities list
            setSelectedUtility(null);  // Clear selected utility
            fetchUtilities();  // Re-fetch the top-level utilities
        } else if (parentUtility) {
            // If in a parent-child view, go back to the parent utility list
            setParentUtility(null);  // Clear parent utility
            fetchUtilities();  // Re-fetch the top-level utilities
        }
    };

    // Handle iframe load event to hide the loader
    const handleIframeLoad = () => {
        setIframeLoading(false); // Stop loading when iframe content is loaded
    };

    // Handle iframe redirection (checking the URL)
    const handleIframeRedirection = () => {
        setIframeLoading(true); // Show loader during redirection
        setIframeUrl(document.location.href); // Update the iframe URL during redirection
    };

    // Render child utilities for the current parent utility
    const renderUtilityHierarchy = (parentId) => {
        const filteredUtilities = utilities.filter((utility) => utility.parentId === parentId);
        if (filteredUtilities.length === 0) return null;
        return filteredUtilities.map((utility) => (
            <div key={utility.id}>
                <div
                    className="utility-card"
                    style={{ backgroundColor: utility.themeColor }}
                    onClick={() => handleUtilityClick(utility)} // Handle click to either route or expand
                >
                    <i className={`${utility.icon} utility-icon`} />
                    <h3>{utility.name}</h3>
                    <p>{utility.description}</p>
                </div>
            </div>
        ));
    };

    // Render top-level utilities (those with parentId === null)
    const renderTopLevelUtilities = () => {
        return filteredUtilities
            .filter((utility) => utility.parentId === null)
            .map((utility) => (
                <div key={utility.id}>
                    <div
                        className="utility-card"
                        style={{ backgroundColor: utility.themeColor }}
                        onClick={() => handleUtilityClick(utility)} // Handle click to either route or expand
                    >
                        <i className={`${utility.icon} utility-icon`} />
                        <h3>{utility.name}</h3>
                        <p>{utility.description}</p>
                    </div>
                </div>
            ));
    };

    return (
        <div>
            {/* Main dashboard heading */}
            {!selectedUtility && !parentUtility && (
                <div className="main-dashboard-heading">
                    <h1>Utility Dashboard</h1> {/* Title for the main dashboard */}
                </div>
            )}

            {/* Search Bar and Button */}
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search utilities..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            {/* Display "Back" button if a parent utility or a utility with a route is selected */}
            {(parentUtility || selectedUtility) && (
                <div className="back-container">
                    <button onClick={handleBackButton} className="back-button">
                        Back
                    </button>
                    <h2>{parentUtility ? parentUtility.name : selectedUtility?.name}</h2> {/* Title of the current parent or selected utility */}
                </div>
            )}

            <div className="utility-dashboard">
                {loading && <div className="loader"></div>}

                {/* Show iframe if a utility with a route is selected */}
                {selectedUtility ? (
                    <div>
                        {/* Show loader if iframe is still loading */}
                        {iframeLoading && <div className="loader"></div>}
                        <iframe
                            title={selectedUtility.name}
                            src={iframeUrl}
                            className="utility-iframe"
                            frameBorder="0"
                            onLoad={handleIframeLoad} // Set the iframe to stop loading once it finishes loading
                            onError={handleIframeRedirection} // Trigger the redirection handler
                            style={{ width: '100vw', height: '100vh', border: 'none' }} // Full-screen iframe
                        ></iframe>
                    </div>
                ) : (
                    // Show either the child utilities or top-level utilities
                    parentUtility
                        ? renderUtilityHierarchy(parentUtility.id) // Show child utilities if parent is selected
                        : renderTopLevelUtilities() // Show top-level utilities
                )}
            </div>
        </div>
    );
};

export default UtilityDashboard;
