import React from "react";
import AboutRow from "../AboutRow/AboutRow";
import InnerHeader from "../InnerHeader/InnerHeader";

import "./TermsPage.scss";

function TermsPage() {
    return (
        <div data-testid="wrapper" class="_loading_overlay_wrapper css-79elbk">
            <div class="searchRsult_page">
                <div class="container">
                    <div class="inner_header_main">
                        <div class="header_wrapper">VILKÅR</div>
                    </div>
                </div>
                <div class="searchRsult_main">
                    <div class="container">
                        <div class="wrapper term-wrapper">
                            <p class="heading filter_heading">
                                <strong>Generelt</strong><div class="mb-2"></div>Disse vilkårene gjelder for bruk av Funtlers AS sine tjenester, inkludert markedsplassen www.funtlers.com, samt tilknyttede sider, apper og andre plattformer som Funtlers AS velger å benytte. Eventuelle tilbud eller priser som er gitt til bedriftskunder kan endres som følge av forhold utenfor Funtlers kontroll, for eksempel endringer i leverandørpriser eller markedssituasjonen. All informasjon, tekst, bilder og annet innhold på våre plattformer er beskyttet av opphavsrett. Innholdet kan ikke kopieres, distribueres eller benyttes uten vårt samtykke, med mindre annet er avtalt. Alle tvister som oppstår i forbindelse med disse vilkårene, skal forsøkes løst gjennom mekling. Hvis mekling ikke fører frem, skal tvisten avgjøres av norske domstoler, og norsk lovgivning skal gjelde. Ved å bruke våre tjenester, godtar du disse vilkårene. Du samtykker til vilkårene ved å opprette en brukerkonto, gjennomføre et kjøp eller på annen måte benytte våre tjenester.<div class="mb-4"></div>
                                <strong>Om Funtlers AS</strong><div class="mb-2"></div>Funtlers AS fungerer som formidler av varer og tjenester fra tredjepartsleverandører, og tilbyr en plattform for kjøp av opplevelsesaktiviteter. Vi påtar oss ikke ansvar for kvaliteten eller utførelsen av aktivitetene som tilbys, og eventuelle tvister knyttet til leverandørenes tjenester skal rettes direkte til dem. For å bruke Funtlers AS sine tjenester, må du være minst 18 år og ha myndighet til å inngå juridiske avtaler. Ved å bruke våre tjenester godtar du både spesifikke vilkår for hvert kjøp og de generelle vilkårene. Du er ansvarlig for å holde passordet til kontoen din konfidensielt, og står til ansvar for kjøp eller endringer gjort gjennom kontoen din.<div class="mb-4"></div>
                                <strong>Behandling av personopplysninger</strong><div class="mb-2"></div>Funtlers AS behandler personopplysninger for å tilby våre tjenester, forbedre brukeropplevelsen og utvikle fremtidige tjenester. Behandlingen skjer i samsvar med gjeldende personvernlovgivning. Vi behandler opplysninger som navn, telefonnummer og e-postadresse, og du er eier av din personlige informasjon. I visse tilfeller kan Funtlers AS være pålagt å behandle dine personopplysninger, for eksempel for regnskapsformål eller ved reklamasjon.<div class="mb-4"></div>
                                <strong>Din bedriftskonto</strong><div class="mb-2"></div>Hver bedriftskunde kan kun opprette én bedriftskonto. Du er ansvarlig for å beskytte kontoens sikkerhet, inkludert passordet, og sørge for at ingen uvedkommende får tilgang til kontoen. Funtlers AS er ikke ansvarlig for tap som følge av misbruk av kontoen, med mindre det skyldes forhold utenfor kundens kontroll. Dersom feilaktig informasjon oppgis, kan kontoen bli stengt, og bedriften kan bli pålagt å dekke eventuelle kostnader som følge av dette.<div class="mb-4"></div>
                                <strong>Avregistrering</strong><div class="mb-2"></div>Du kan enkelt avregistrere deg eller bedriften din fra www.funtlers.com. Hvis du ønsker å slette kontoen din, send en e-post til <a href="mailto:support@funtlers.com">support@funtlers.com</a>, og vi vil fjerne din informasjon fra vår database.<div class="mb-4"></div>
                                <strong>Bruk av informasjonskapsler</strong><div class="mb-2"></div>Vi benytter informasjonskapsler for å forbedre brukeropplevelsen på våre plattformer. Informasjonskapslene gir oss muligheten til å analysere hvordan du bruker nettsiden vår, og tilpasse innholdet etter dine preferanser.<div class="mb-4"></div>
                                <strong>Priser</strong><div class="mb-2"></div>Alle priser på Funtlers AS sine plattformer er oppgitt i NOK og inkl MVA.<div class="mb-4"></div>
                                <strong>Kjøp – Privatkunder</strong><div class="mb-2"></div>For privatkunder kan kjøp gjøres direkte på Funtlers AS sin markedsplass via nettsiden eller mobilnettstedet. Når aktiviteten er booket, mottar du en kjøpsbekreftelse på den registrerte e-posten. I bekreftelsen finner du informasjon om aktiviteten, leverandøren, kontaktinformasjon til leverandøren, samt din unike Funtlers-kode. Denne koden gir du til leverandøren ved bestilling og fungerer som bevis på forhåndsbetaling.<div class="mb-4"></div>
                                <strong>Kjøp – Bedriftskunder</strong><div class="mb-2"></div>For bedriftskunder kan kjøp gjøres via bedriftsplattformen, eller ved å legge inn bestilling via e-post eller telefondialog med Funtlers ansatte. Etter at aktiviteten er booket, vil en bekreftelse med bookingdetaljer bli sendt til den registrerte e-posten.<div class="mb-4"></div>
                                <strong>Betaling – Privatkunder</strong><div class="mb-2"></div>For privatkunder benytter Funtlers AS Stripe som betalingspartner. Med annet er avtalt direkte med Funtlers ansatte vil alle betalinger skjer via vår markedsplass, og betalingsprosessen er sikret med SSL-kryptering for å beskytte din informasjon. Du kan betale direkte på nettsiden med Visa, Mastercard eller andre tilgjengelige betalingsalternativer. For mer informasjon om hvordan Stripe håndterer dine personopplysninger, kan du lese Stripe sin personvernserklæring.<div class="mb-4"></div>
                                <strong>Betaling – Bedriftskunder</strong><div class="mb-2"></div>For bedriftskunder betales aktiviteten via faktura. Når aktiviteten er booket, vil Funtlers AS sende en faktura til den registrerte fakturaadressen. Fakturaen må betales innen forfall. Dersom betalingen ikke skjer innen fristen, påløper et forsinkelsesgebyr på 99 kr per purring. Ved betaling etter forfall beregnes rente i henhold til forsinkelsesrenteloven. Har du spørsmål om faktureringen, kan du kontakte oss direkte.<div class="mb-4"></div>
                                <strong>Angrerett</strong><div class="mb-2"></div>Både privatkunder og bedriftskunder har 14 dagers angrerett fra kjøpsdatoen. Angreretten gjelder ikke dersom aktivitetskoden er brukt, eller hvis rabatten gjelder opplevelser som ikke dekkes av angreretten, som for eksempel billetter til konserter eller arrangementer. For å benytte angreretten, må kunden informere Funtlers AS skriftlig innen 14 dager fra kjøpsdatoen.<div class="mb-4"></div>
                                <strong>Avbestillingsvilkår – Privatkunder</strong><div class="mb-2"></div>Ved avbestilling av bestilt aktivitet etter at kjøp er gjennomført, gjelder en angrerett på 14 dager. Etter denne perioden kan ikke angreretten benyttes.<div class="mb-4"></div>
                                <strong>Avbestillingsvilkår – Bedriftskunder</strong><div class="mb-2"></div>For bedriftskunder gjelder avbestillingsbetingelser i henhold til avtale med Funtlers. Ved avbestilling innen 14 dager før planlagt aktivitet, påløper en avbestillingsavgift på 15 % av bestillingssummen. Ved avbestilling etter 14 dager eller dersom aktiviteten ikke gjennomføres grunnet bedriftskunden, kan Funtlers kreve full betaling for aktiviteten. Avbestilling eller endring av avtale skal alltid skje skriftlig.<div class="mb-4"></div>
                                <strong>Bruk av rabatten</strong><div class="mb-2"></div>Rabatten på aktivitetene kjøpt via www.funtlers.com er kun gyldig i 3 måneder etter kjøp. Dersom aktiviteten ikke benyttes innen denne perioden, vil rabatten og gyldigheten av den unike rabattkoden bortfalle.<div class="mb-4"></div>
                                <strong>Gavekort</strong><div class="mb-2"></div>Man kan kjøpe et gavekort hos Funtlers og gi bort opplevelser fra vårt utvalg. Gavekortet leveres digitalt på kjøperens e-post med en PDF av digitalt gavekort. På gavekort-PDFen vil det være en unik gavekortkode som brukes for å booke aktiviteter direkte med leverandøren. Den unike koden er et bevis på at aktiviteten er betalt på forhånd som gavekort. Gavekortene har en gyldighetsperiode på 6 måneder fra kjøpsdato, og ubenyttet beløp bortfaller etter utløp. Gavekort kan ikke byttes mot kontanter, refunderes eller brukes til angrerett. Funtlers AS er ikke ansvarlig for tap eller misbruk av gavekort. Oppbevar gavekortet trygt, da vi ikke kan erstatte det ved tap eller tyveri.<div class="mb-4"></div>
                                <strong>Force Majeure</strong><div class="mb-2"></div>Funtlers AS kan ikke holdes ansvarlig for forsinkelser eller manglende levering av tjenester som skyldes forhold utenfor vår kontroll, herunder, men ikke begrenset til, naturkatastrofer, krig, streik eller tekniske problemer. I slike tilfeller vil vi gjøre vårt beste for å informere kundene våre og finne alternative løsninger.<div class="mb-4"></div>
                                <strong>Innsendte idéer</strong><div class="mb-2"></div>Funtlers AS forbeholder seg retten til å bruke innkommende forslag, som for eksempel idéer til aktiviteter eller plattformutvikling, uten ytterligere kompensasjon til innsenderen.<div class="mb-4"></div>
                                <strong>Linker til andre sider</strong><div class="mb-2"></div>Våre plattformer kan inneholde lenker til eksterne nettsteder som ikke eies av Funtlers AS. Vi er ikke ansvarlige for innholdet på disse nettstedene.<div class="mb-4"></div>
                                <strong>Kommentarer i sosiale medier</strong><div class="mb-2"></div>Funtlers AS gir brukere mulighet til å kommentere og stille spørsmål på våre plattformer. Brukeren er ansvarlig for innholdet i disse kommentarene, og Funtlers AS kan fjerne kommentarer som oppfattes som spam eller krenker tredjeparts rettigheter.<div class="mb-4"></div>
                                <strong>Kontakt</strong><div class="mb-2"></div>For spørsmål om våre brukervilkår, vennligst kontakt vår kundeservice på <a href="mailto:support@funtlers.com">support@funtlers.com</a>.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    //<div className="about_page">
    //  <InnerHeader
    //    HeaderHeading="Brukervilkår"
    //    PageText={"ALMINNELIGE VILKÅR FOR BRUK AV FUNTLERS AS SINE TJENESTER"}
    //    hideHome={false}
    //  />
    //  <div className="about_main">
    //    <div className="container">
    //      <div className="">
    //        <h2 className="heading-h2">Generelt</h2>
    //        <p className="heading-s">
    //          Følgende vilkår gjelder for bruk av tjenester fra Funtlers AS
    //          herunder nettsiden{" "}
    //          <a
    //            target={"_blank"}
    //            rel="noreferrer"
    //            href="https://www.funtlers.com/"
    //          >
    //            www.funtlers.com{" "}
    //          </a>{" "}
    //          og alle tilknyttede sider, apper, mobilsider og andre plattformer
    //          som Funtlers AS velger å benytte for sine tjenester.
    //          <div class="mb-4"></div> <div class="mb-4"></div>
    //          Disse vilkårene gjelder for bruk av Funtlers AS tjenester uansett
    //          hvilken plattform du bruker tjenesten på. Bruken av Funtlers AS
    //          tjenester innebærer at du godtar disse vilkårene. Du godtar
    //          vilkårene ved å opprette en brukerkonto, gjennomfører et kjøp
    //          eller på annen måte tar i bruk Funtlers AS tjenester.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Om Funtlers AS </h2>
    //        <p className="heading-s">
    //          Funtlers AS er en formidler av andre sine varer og tjenester.
    //          Funtlers AS sin viktigste funksjon er å legge til rette for at
    //          våre brukere/medlemmer gis mulighet til å kjøpe
    //          opplevelsesaktiviteter fra tredjepartsleverandører som Funtlers AS
    //          har forhandlet med på brukernes vegne. Det vil fremgå tydelig av
    //          hvert enkelt tilbud hvem som er selger /tjenesteyter og hva
    //          rabatten er.
    //          <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          For å bruke Funtlers AS sine tjenester gjelder følgende vilkår:
    //        </p>
    //        <ul className="heading-s  ul-style">
    //          <li>Du må være minst 18 år for å foreta et kjøp</li>
    //          <li>
    //            Du besitter myndighet til å inngå skriftlige, juridiske avtaler
    //          </li>
    //          <li>
    //            Du forstår og er enig med alle de spesifikke vilkår som
    //            presenteres i hvert kjøp, samt i dette samlede dokumentet
    //          </li>
    //          <li>
    //            Du er enig i at det er ditt ansvar å holde passordet til kontoen
    //            konfidensielt og å sjekke at din egen konto ikke bli brukt av
    //            uvedkommende
    //          </li>
    //          <li>
    //            Vår anbefaling er at du velger et passord med høy sikkerhet (Ex.
    //            både store og små bokstaver og spesialtegn)
    //          </li>
    //          <li>
    //            Kontoen din er personlig og bør ikke brukes av noen andre, selv
    //            med skriftlig eller muntlig tillatelse
    //          </li>
    //          <li>
    //            All informasjon du gir Funtlers AS i alle prosesser som er
    //            utført på våre plattformer må være nøyaktig og fullstendig
    //          </li>
    //        </ul>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Behandling av personopplysninger</h2>
    //        <p className="heading-s">
    //          Funtlers AS behandler personopplysninger om deg som kunde for å
    //          kunne tilby våre tjenester, forbedre brukeropplevelsen og utvikle
    //          eksisterende og fremtidige tjenester vi tilbyr. Behandlingen av
    //          personopplysninger som Funtlers AS utfører, er i samsvar med
    //          gjeldende lov.
    //          <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Funtlers AS behandler en rekke personopplysninger, som navn,
    //          telefonnummer, e-postadresse osv. For oss på Funtlers AS , er det
    //          viktig å understreke at det er du som er eier av din personlige
    //          informasjon, og vi går alltid i fra at du selv bestemmer hva din
    //          personlige informasjon vil bli brukt til. Det kan imidlertid være
    //          tilfeller der Funtlers AS , i henhold til loven, er pålagt å
    //          behandle dine personopplysninger. Fortsatt behandling kan
    //          forekomme, for eksempel for regnskapsmessige formål eller ved
    //          reklamasjonshenvendelser, da vi må lagre noen av dine personlige
    //          data for å oppfylle våre lovbestemte forpliktelser.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Din konto</h2>
    //        <p className="heading-s">
    //        Hver bruker kan kun opprette én personlig konto. Brukeren er selv er ansvarlig for sikkerheten omkring sin konto, for eksempel at ikke passordet kommer på avveie og at ikke andre gis tilgang til kontoen. Funtlers AS er ikke ansvarlig for tap eller ulempe som følge av misbruk av din konto såfremt slik tap ikke skyldes forhold innenfor Funtlers AS sin kontroll. Hvis du oppgir uriktig info under registreringsprosessen eller senere, forbeholder Funtlers AS seg retten til å stenge kontoen og eventuelt kreve erstatning for økonomisk tap eller kostnader som har påløpt som følge av dette.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Avregistrering</h2>
    //        <p className="heading-s">
    //        Det er lett å avregistrere seg fra www.funtlers.com . Hvis du av en eller annen grunn vil slette kontoen din, send en e-post til{" "}
    //          <a
    //            target={"_blank"}
    //            rel="noreferrer"
    //            href="https://www.funtlers.com/"
    //          >
    //            www.funtlers.com{" "}
    //          </a>
    //          , slik at informasjonen din blir fjernet fra vår database.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">
    //          Funtlers AS sin bruk av informasjonskapsler
    //        </h2>
    //        <p className="heading-s">
    //          Vi i Funtlers AS mener at bruk av informasjonskapsler hjelper deg
    //          som kunde, fordi det gir oss mulighet til å forbedre
    //          brukeropplevelsen ved å forutse hvordan du bruker nettsiden vår,
    //          basert på dine preferanser.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Priser</h2>
    //        <p className="heading-s">Samtlige priser er oppgitt i NOK.</p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Kjøp</h2>
    //        <p className="heading-s">
    //          Kjøp kan kun gjøres gjennom Funtlers AS nettside eller
    //          mobilnettsted. Vi aksepterer ikke kjøp via telefon, faks eller
    //          e-post. <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Funtlers AS fungerer som en annonseplattform for de leverandørene
    //          som presenteres på siden. Funtlers AS er kun en formidler av de
    //          tjenester og produkter som publiseres. Avtale om kjøp av aktivitet
    //          som du gjør inngås dermed kun med selskapet som er beskrevet i
    //          tilbudet og alt kjøpsrettslig ansvar ligger hos den respektive
    //          leverandøren. <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Ved kjøp godkjenner du at Funtlers AS ikke kan holdes ansvarlig
    //          for eventuelle reklamasjoner ved produktet eller tjenesten,
    //          herunder, inkludert, men ikke begrenset til, feil eller mangler,
    //          forsinkelse på levering, ikke-tilfredsstillende kundeopplevelse,
    //          vare/tjeneste ikke som beskrevet eller ikke tilgjengelig
    //          leverandør. Funtlers AS tar forbehold om skrivefeil i opplysninger
    //          om dealen, vilkår og prissetting.
    //        </p>
    //      </div>

    //      <div className="terms-section">
    //        <h2 className="heading-h2">Betaling</h2>
    //        <h3 className="heading-h2 sub-heading">Stripe</h3>
    //        <p className="heading-s sub-text">
    //          Funtlers AS benytter seg av Stripe for betaling. 
    //          <div class="mb-4"></div>
    //        </p>
    //        <h3 className="heading-h2 sub-heading">Betaling med Stripe</h3>
    //        <p className="heading-s sub-text">
    //          Betalingen blir behandlet av Stripe som er en sikker elektronisk
    //          betalingspartner. Betalingssiden er sikret med SSL og alle
    //          detaljer er kryptert. Se for øvrig Stripe's personvernserklæring. 
    //        </p>

    //        <h3 className="heading-h2 sub-heading">Kortbetaling</h3>
    //        <p className="heading-s sub-text">
    //          Velger du kortbetaling kan dette gjøres med hjelp av Visa eller
    //          Mastercard. For å benytte dette betalingsalternativet må du angi
    //          kortnummer, kortets gyldighetstid samt CVC-koden. Ingen avgift
    //          tilkommer ved kjøp gjennom kortbetaling. <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Du kan enkelt velge at Stripe skal huske dine kortdetaljer, dette
    //          for å forenkle fremtidige kjøp.
    //        </p>
    //      </div>

    //      {/* <div className="terms-section">
    //        <h2 className="heading-h2">Betaling</h2>
    //        <h3 className="heading-h2 sub-heading">Klarna Checkout</h3>
    //        <p className="heading-s">
    //          Funtlers AS benytter Klarna Checkout som betalingsløsning. Klarna
    //          Checkout er trygg og enkel og tilbyr deg som kunde flere ulike
    //          betalingsalternativer. Du kan velge mellom alternativene Faktura,
    //          Delbetaling eller Kortbetaling. <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Klarna Checkout skiller på kjøp og betaling, hvilket innebærer at
    //          du først etter bekreftet kjøp velger betalingsatlernativ. Under
    //          følger en mer utfyllende forklaring på de ulike alternativene du
    //          kan velge mellom. For fullstendige vilkår til Klarna Checkout, se{" "}
    //          <a
    //            target={"_blank"}
    //            rel="noreferrer"
    //            href="https://www.klarna.com/no/vilkar"
    //          >
    //            www.klarna.com/no/vilkar{" "}
    //          </a>{" "}
    //          <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Merk at Klarna kan oppdatere sine vilkår. I tilfelle konflikt
    //          mellom disse betalingsbetingelsene og Klarnas vilkår, gjelder
    //          Klarnas vilkår. Ved eventuelle spørsmål er du alltid velkommen til
    //          å ringe Klarna på telefonummer 21 01 89 91 alle hverdager mellom
    //          kl. 08.00 – 17:00.
    //        </p>

    //        <h3 className="heading-h2 sub-heading">Kortbetaling</h3>
    //        <p className="heading-s">
    //          Velger du kortbetaling kan dette gjøres med hjelp av Visa eller
    //          Mastercard. For å benytte dette betalingsalternativet må du angi
    //          kortnummer, kortets gyldighetstid samt CVC-koden. Ingen avgift
    //          tilkommer ved kjøp gjennom kortbetaling. <div class="mb-4"></div>
    //          <div class="mb-4"></div> Du kan enkelt velge at Klarna skal huske dine kortdetaljer,
    //          dette for å forenkle fremtidige kjøp.
    //        </p>

    //        <h3 className="heading-h2 sub-heading">
    //          Klarnas behandling av personopplysninger{" "}
    //        </h3>
    //        <p className="heading-s">
    //          Funtlers AS og Klarna er sammen personopplysningsansvarlige for
    //          behandling av personopplysninger koblet til det
    //          betalingsalternativet du velger ved kjøp. For en detaljert
    //          redgjørelse over hva dette innebærer, les vår integritetspolicy og
    //          Klarnas databeskyttelsespolicy.
    //        </p>
    //      </div> */}

    //      <div className="terms-section">
    //        <h2 className="heading-h2">Angrerett</h2>
    //        <p className="heading-s">
    //          Angrefristen rettes mot leverandøren av den aktuelle aktiviteten.
    //          Funtlers AS forplikter seg ikke til å behandle angresaken, og ved
    //          kjøp er kunde inneforstått med at kjøpsavtalen er inngått mellom
    //          kunde og oppgitt leverandør av aktiviteten. <div class="mb-4"></div>
    //          <div class="mb-4"></div> Det er imidlertid visse vilkår som må fylles for at
    //          angreretten skal være gyldig. Retten til å angre frafaller ved
    //          følgende tilfeller:
    //        </p>
    //        <ul className="heading-s ul-style">
    //          <li>Hvis aktivitets-koden er brukt</li>
    //          <li>
    //            Hvis rabatten gjelder bransjer/opplevelser som ikke dekkes av
    //            angreretten (for eksempel billetter til arrangementer/konserter)
    //          </li>
    //        </ul>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">
    //          {" "}
    //          Ikke gjennomført booking og avbestillingsgebyr
    //        </h2>
    //        <p className="heading-s">
    //          Dersom aktivitet kjøpt gjennom en rabatt på{" "}
    //          <a
    //            target={"_blank"}
    //            rel="noreferrer"
    //            href="https://www.funtlers.com/"
    //          >
    //            www.funtlers.com{" "}
    //          </a>{" "}
    //          ikke blir benyttet innenfor frist, og du forholder deg ellers helt
    //          passiv, vil dette ikke gi leverandør av aktiviteten noen
    //          indikasjoner på hvorfor aktiviteten ikke er innløst. I
    //          Angrerettlovens § 11 og § 15 står det spesifisert at disse
    //          vilkårene gjør at du som kunde mister rettighet til å kansellere
    //          avtalen. <div class="mb-4"></div>
    //          <div class="mb-4"></div>
    //          Ved tilfelle at du har gjort en bestilling hos en av våre partnere
    //          og deretter uteblitt fra den bestilte aktiviteten, forbeholder
    //          Funtlers AS og tilbyder seg retten til å belaste deg som kunde et
    //          gebyr som tilsvarer aktivitetsprisen.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Bruk av rabatten</h2>
    //        <p className="heading-s">
    //          Dersom aktivitet kjøpt gjennom en rabatt på{" "}
    //          <a
    //            target={"_blank"}
    //            rel="noreferrer"
    //            href="https://www.funtlers.com/"
    //          >
    //            www.funtlers.com{" "}
    //          </a>{" "}
    //          ikke blir benyttet innenfor frist, og du forholder deg ellers helt
    //          passiv, vil dette ikke gi leverandør av aktiviteten noen
    //          indikasjoner på hvorfor aktiviteten ikke er innløst. I
    //          Angrerettlovens § 11 og § 15 står det spesifisert at disse
    //          vilkårene gjør at du som kunde mister rettighet til å kansellere
    //          avtalen.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Innsendte idéer</h2>
    //        <p className="heading-s">
    //          Funtlers AS forbeholder seg retten til å bruke innkommende forslag
    //          (slik som forslag til aktiviteter, utvikling av plattformen, til
    //          nye tjenester osv.) eller annet som kommuniseres til Funtlers AS
    //          uten ytterligere informasjon til/om innsender eller belønning
    //          til/av innsender.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Linker til andre sider</h2>
    //        <p className="heading-s">
    //          Alle våre plattformer kan inneholde linker til andre nettsider som
    //          ikke er eid av Funtler AS s. Vi er ikke ansvarlige for innholdet
    //          på disse sidene.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Kommentarer i sosiale medier</h2>
    //        <p className="heading-s">
    //          Funtlers AS lar i utvalgte tilfeller sine brukere kommentere,
    //          stille spørsmål eller på annen måte kommunisere direkte med
    //          Funtlers AS på en måte som er synlig for alle brukere. Hver enkelt
    //          bruker er ansvarlig for innholdet i disse kommentarene, og
    //          Funtlers AS verken støtter eller er medvirkende til hva som
    //          skrives av våre brukere og av andre. Hver bruker er også selv
    //          ansvarlig for å sette seg inn i hvem andre som kan se
    //          informasjonen som skrives, og legger inn kommentarer på eget
    //          ansvar. Det er ikke lov til å skrive kommentarer i andres navn
    //          eller benytte falskt navn. Funtlers AS forbeholder seg retten til
    //          å fjerne eller skjule kommentarer uten å oppgi grunn. Kommentarer
    //          som oppfattes som reklame (”spam”) vil fjernes umiddelbart. Det er
    //          ikke lov til å legge ut kopibeskyttet materiale på våre sider.
    //          Innhold som krenker tredjeparts rettigheter vil bli fjernet så
    //          snart vi får melding om dette.
    //        </p>
    //      </div>
    //      <div className="terms-section">
    //        <h2 className="heading-h2">Kontakt</h2>
    //        <p className="heading-s">
    //          Hvis du har spørsmål om våre brukervilkår, ta gjerne kontakt med
    //          vår kundeservice support@funtlers.com
    //        </p>
    //      </div>
    //    </div>
    //  </div>
    //</div>
  );
}

export default TermsPage;
