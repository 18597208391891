import "./App.scss";
import { Routes, Route, BrowserRouter , Switch } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ContactPage from "./components/ContactPage/ContactPage";
import FaqPage from "./components/FaqPage/FaqPage";
import AboutPage from "./components/AboutPage/AboutPage";
import PartnersPage from "./components/PartnersPage/PartnersPage";
import Activities from "./components/Activities/Activities";
import ActivityDetails from "./components/Activity-Details/ActivityDetails";
import Payments from "./components/Payments/Payments";
import Login from "./components/Login/Login";
import SearchResultPage from "./components/SearchResultPage/SearchResultPage";
import TeamPage from "./components/TeamPage/TeamPage";
import BookingConPage from "./components/BookingConPage/BookingConPage";
import CampaignPage from "./components/CampaignPage/CampaignPage";
import Register from "./components/Register/Register";
import React, { useState, useEffect } from "react";
import AddActivity from "./components/AddActivity/AddActivity";
import AddPartner from "./components/AddPartner/AddPartner";
import AddPartner2 from "./components/AddPartner/AddPartner2";

import EditPartner from "./Dashboard/EditPartner/EditPartner";
import EditPartner2 from "./Dashboard/EditPartner/EditPartner2";

import ProfilePage from "./Dashboard/ProfilePage/ProfilePage";
import DashboardHeader from "./Dashboard/DashboardHeader/DashboardHeader";

import PartnerDashboardHeader from "./Dashboard/PartnerDashboardHeader/PartnerDashboardHeader";

import DashboardSidebar from "./Dashboard/DashboardSidebar/DashboardSidebar";
import EditProfilePage from "./Dashboard/EditProfilePage/EditProfilePage";
import ActivityPage from "./Dashboard/ActivityPage/ActivityPage";
import PartnerPage from "./Dashboard/PartnerPage/PartnerPage";
import PartnerPage2 from "./Dashboard/PartnerPage/PartnerPage2";


import PartnerOrderPage from "./Dashboard/PartnerOrders/PartnerOrder";
import SearchEdit from "./Dashboard/SearchEdit/SearchEdit";
import SearchEditFunctional from "./Dashboard/SearchEditFunctional/SearchEditFunctional";

import AdminActivated from "./components/AdminActivated/AdminActivated";
import CouponsController from "./components/CouponsController/CouponsController";
import CustomerPage from "./components/CustomerPage/CustomerPage";
import PartnerAnalyticsPage from "./Dashboard/PartnerAnalyticsPage/PartnerAnalyticsPage";
import OrderPage from "./Dashboard/OrderPage/OrderPage";
import EditOrderPage from "./Dashboard/EditOrderPage/EditOrderPage";
import LoadingOverlay from "react-loading-overlay";
import EditActivity from "./Dashboard/EditActivity/EditActivity";
import { isLoggedIn } from "./api/NewLoginService";
import { useLocation, useNavigate } from "react-router-dom";
import PartnerDashboardSidebar from "./Dashboard/PartnerDashboardSidebar/PartnerDashboardSidebar";
import FeaturesPage from "./components/FeaturesPage/FeaturesPage";
import AdminLandingPage from "./Dashboard/AdminLandingPage/AdminLandingPage";
import TermsPage from "./components/TermsPage/TermsPage";
import EditProfile from "./components/EditProfile/EditProfile";
import OrderedActivity from "./components/OrderedActivity/OrderedActivity";
import PartnerActivities from "./Dashboard/PartnerActivities/PartnerActivities";
import AnalyticsPage from "./Dashboard/Analytics/AnalyticsPage";
import PartnerProfilePage from "./Dashboard/PartnerProfilePage/PartnerProfilePage";
//importing images for email
import image1 from "./img/image-1.png";
import image2 from "./img/image-2.png";
import image3 from "./img/image-3.png";
import image4 from "./img/image-4.png";
import image5 from "./img/image-5.png";
import image6 from "./img/image-6.png";
import image7 from "./img/image-7.png";
import ResetPassword from "./components/ResetPassword/ResetPassword";

import ReactGA from "react-ga4";
import UniqueCodes from "./Dashboard/UniqueCodes/UniqueCodes";
import ImagesEdit from "./Dashboard/ImagesEdit/ImagesEdit";
import PartnerLandingPage from "./Dashboard/PartnerLandingPage/PartnerLandingPage";
import OrderPlan from "./components/OrderPlans/OrderPlan";
import OrderPlan2 from "./components/OrderPlans/OrderPlan2";
import UtilityDashboard from "./Dashboard/UtiltiyDashboard/UtilityDashboard";
import DynamicSearchScreen from "./Dashboard/DynamicSearchScreen/SearchScreenComponent/DynamicSearchScreen";

function App() {
    const [SideBar, setSideBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    const [showLogin, setShowLogin] = useState(false);
    const [button1, setButton1] = useState(false);
    const [button2, setButton2] = useState(false);
    const [button3, setButton3] = useState(false);
    const [editButtons, setEditButtons] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const [userObject, setUser] = useState(isLoggedIn());


    const handleIframeLoad = () => {
        // Initially set isLoading to true when the route changes
        setIsLoading2(true);

        // Wait for 3 seconds and then set loading to false
        setTimeout(() => {
            setIsLoading2(false); // Set loading to false after 3 seconds
        }, 500); // 3 seconds timeout
    };


    const handleNavbarClick = () => {
        debugger;
        //setIsLoading(true); // Set loading to true when navbar item is clicked

    };


    const navigate = useNavigate();

    ReactGA.initialize("G-KHEFX4PNTM");
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse) {
        navbarCollapse.addEventListener('click', handleNavbarClick);
    }


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        debugger;

        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse) {
            navbarCollapse.addEventListener('click', handleNavbarClick);
        }

        // Clean up event listener when the component is unmounted
        return () => {
            if (navbarCollapse) {
                navbarCollapse.removeEventListener('click', handleNavbarClick);
            }
        };


    }, []);

    const SideMenuClick = () => {
        setSideBar(!SideBar);
    };
    const CloseSideBar = () => {
        setSideBar(true);
    };
    return userObject && userObject.user.userTypeId == 3 ? (
        <>

            <LoadingOverlay
                active={isLoading}
                spinner
                text="Behandler forespørselen din"
            >
                <div className="dashboard_main">
                    <div className="sidebar_content_main">
                        <div className="sidebar_main">
                            <PartnerDashboardSidebar
                                SideBarState={SideBar}
                                CloseSideBar={CloseSideBar}
                                SidebarAddClass={SideBar}
                                SidebarStrech={SideMenuClick}
                            />
                        </div>
                        <div className="Header_content_main">
                            <div className="dashboard_header_main">
                                <PartnerDashboardHeader
                                    SidebarStrech={SideMenuClick}
                                    setUser={setUser}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                />
                            </div>
                            <div className="content_main">
                                <Routes>
                                    <Route
                                        path="/login"
                                        exact={true}
                                        element={<Login setIsLoading={setIsLoading} />}
                                    />

                                    <Route
                                        path="*"
                                        exact={true}
                                        element={<PartnerProfilePage />}
                                    />
                                    <Route
                                        path="/"
                                        exact={true}
                                        element={<PartnerLandingPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/aktivitet/:name"
                                        exact={true}
                                        element={
                                            <CampaignPage
                                                userType="partner"
                                                setShowLogin={setShowLogin}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/aktiviteter"
                                        exact={true}
                                        element={<PartnerActivities source="partner" setIsLoading={setIsLoading} />}
                                    />

                                    <Route
                                        path="/profilene"
                                        exact={true}
                                        element={<PartnerProfilePage />}
                                    />
                                    <Route
                                        path="/profil-redigere"
                                        exact={true}
                                        element={<EditProfilePage mode="partner" />}
                                    />

                                    <Route
                                        path="/bestilt-aktivitet"
                                        exact={true}
                                        element={<OrderedActivity setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/partner-analytics"
                                        exact={true}
                                        element={
                                            <PartnerAnalyticsPage setIsLoading={setIsLoading} />
                                        }
                                    />
                                    <Route
                                        path="/rekkefølge"
                                        exact={true}
                                        element={<PartnerOrderPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/rediger-rekkefølge"
                                        exact={true}
                                        element={<EditOrderPage />}
                                    />
                                    <Route
                                        path="/orderplan/:action"
                                        exact={true}
                                        element={<OrderPlan
                                            searchQuery={searchQuery}
                                            setSearchQuery={setSearchQuery} editButtons={editButtons} />}
                                    />
                                    <Route
                                        path="/orderplans/:action"
                                        exact={true}
                                        element={<OrderPlan2
                                            searchQuery={searchQuery}
                                            setSearchQuery={setSearchQuery} editButtons={editButtons} />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    ) : userObject && userObject.user.userTypeId == 2 ? (
        <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text="Behandler forespørselen din"
            >
                <div className="dashboard_main">
                    <div className="sidebar_content_main">
                        <div className="sidebar_main dashboard-header-color">
                            <DashboardSidebar
                                SidebarAddClass={SideBar}
                                SidebarStrech={SideMenuClick}
                            />
                        </div>
                        <div className="Header_content_main dashboard-header-color">
                            <div className="dashboard_header_main dashboard-header-color">
                                <DashboardHeader
                                    SidebarStrech={SideMenuClick}
                                    setUser={setUser}
                                    userObject={userObject}
                                />
                            </div>
                            <div className="content_main dashboard-body-color">
                                    <Routes>
                                        <Route
                                            path="/login"
                                            exact={true}
                                            element={<Login setIsLoading={setIsLoading} />}
                                        />

                                    <Route
                                        path="/"
                                        exact={true}
                                        element={<AdminLandingPage setIsLoading={false} />}
                                        />
                                        <Route
                                            path="/b2b"
                                            exact={true}
                                            element={<PartnerLandingPage setIsLoading={setIsLoading} />}
                                        />
                                    <Route
                                        path="/aktivitet/:name"
                                        exact={true}
                                        element={
                                            <CampaignPage
                                                userType="partner"
                                                setShowLogin={setShowLogin}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/bestillingsbekreftelse"
                                        exact={true}
                                        element={<BookingConPage />}
                                    />
                                    <Route
                                        path="/aktivitet-side"
                                        exact={true}
                                        element={<ActivityPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/kuponger"
                                        exact={true}
                                        element={<CouponsController setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/add-aktivitet"
                                        exact={true}
                                        element={<AddActivity setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/redigere-aktivitet"
                                        exact={true}
                                        element={<EditActivity setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/legge-til-partner"
                                        exact={true}
                                        element={<AddPartner />}
                                        />

                                        <Route
                                            path="/legge-til-bedriftskunde"
                                            exact={true}
                                            element={<AddPartner2 />}
                                        />
                                    <Route
                                        path="/rediger-partner"
                                        exact={true}
                                        element={<EditPartner />}
                                    />

                                        <Route
                                            path="/rediger-partner2"
                                            exact={true}
                                            element={<EditPartner2 />}
                                        />
                                    <Route
                                        path="/profilene"
                                        exact={true}
                                        element={<ProfilePage />}
                                    />
                                    <Route
                                        path="/profil-redigere"
                                        exact={true}
                                        element={<EditProfilePage mode="admin" />}
                                    />
                                    <Route
                                        path="/partner-dashbordet"
                                        exact={true}
                                        element={<PartnerPage setIsLoading={setIsLoading} />}
                                        />

                                        <Route
                                            path="/partner-dashbordet2"
                                            exact={true}
                                            element={<PartnerPage2 setIsLoading={setIsLoading} />}
                                        />



                                        <Route
                                            path="/utility-dashbaord"
                                            exact={true}
                                            element={<UtilityDashboard />}
                                        />


                                        <Route path="/search/:screenName" element={<DynamicSearchScreen />} />  {/* Define the route */}
                                     


                                        <Route
                                            path="/hangfire-jobber"
                                            exact={true}
                                            element={

                                                <div style={{ position: 'relative', height: '100vh' }}>
                                                    {isLoading2 && (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%',
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                border: '4px solid #f3f3f3',
                                                                borderTop: '4px solid #fc9765',
                                                                borderRadius: '50%',
                                                                width: '50px',
                                                                height: '50px',
                                                                animation: 'spin 1s linear infinite',
                                                            }}
                                                        ></div>
                                                    )}
                                                    <iframe
                                                        src="/hangfire"  // The Hangfire dashboard path
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            border: 'none',
                                                        }}
                                                        title="Hangfire Dashboard"
                                                        onLoad={handleIframeLoad} // Hide loader when iframe loads
                                                    />
                                                </div>
                                            }
                                        />

                                    <Route
                                        path="/partner-bestillinger"
                                        exact={true}
                                        element={<PartnerOrderPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/partner-aktiviteter"
                                        exact={true}
                                        element={<PartnerActivities source="admin" setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/analyse"
                                        exact={true}
                                        element={<AnalyticsPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/rekkefølge"
                                        exact={true}
                                        element={<OrderPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/rediger-rekkefølge"
                                        exact={true}
                                        element={<EditOrderPage setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/bestilt-aktivitet"
                                        exact={true}
                                        element={<OrderedActivity setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/unike-koder"
                                        exact={true}
                                        element={<UniqueCodes setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/søke"
                                        exact={true}
                                        element={<SearchEdit setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/søk-rediger"
                                        exact={true}
                                        element={<SearchEditFunctional setIsLoading={setIsLoading} />}
                                    />
                                    <Route
                                        path="/bilder"
                                        exact={true}
                                        element={<ImagesEdit setIsLoading={setIsLoading} />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>
    ) : (
        //THis is customer side
                <>
            <LoadingOverlay
                active={isLoading}
                spinner
                text="Behandler forespørselen din"
            >
                <Header
                    setShowLogin={setShowLogin}
                    showLogin={showLogin}
                    setUser={setUser}
                    userObject={userObject}
                />
                        <Routes>
                            <Route
                                path="/login"
                                exact={true}
                                element={<Login setIsLoading={setIsLoading} />}
                            />

                    <Route
                        path="/"
                        exact={true}
                        element={<LandingPage setIsLoading={setIsLoading} />}
                    />
                    <Route
                        path="/logg-inn"
                        exact={true}
                        element={<Login userObject={userObject} setUser={setUser} />}
                    />
                    <Route
                        path="/a-x-a-now"
                        exact={true}
                        element={<AdminActivated />}
                    />
                    <Route
                        path="/reset-password"
                        exact={true}
                        element={<ResetPassword />}
                    />
                    <Route path="/kontakt" exact={true} element={<ContactPage />} />
                    <Route path="/faq" exact={true} element={<FaqPage />} />
                    <Route path="/omtrent" exact={true} element={<AboutPage />} />
                    <Route path="/vilkar" exact={true} element={<TermsPage />} />
                    <Route path="/partneren" exact={true} element={<PartnersPage />} />
                    <Route path="/activity" exact={true} element={<Activities />} />
                    <Route path="/betaling" exact={true} element={<Payments />} />
                    <Route path="/registrere" exact={true} element={<Register />} />
                    <Route
                        path="/aktiviteter"
                        exact={true}
                        element={<SearchResultPage />}
                    />
                    <Route path="/teamet" exact={true} element={<TeamPage />} />
                    <Route
                        path="/bestillingsbekreftelse"
                        exact={true}
                        element={<BookingConPage />}
                    />
                    <Route
                        path="aktivitet/:name"
                        exact={true}
                        element={
                            <CampaignPage userType="customer" setShowLogin={setShowLogin} />
                        }
                    />

                    <Route
                        path="/hvorfor-velge-oss"
                        exact={true}
                        element={<FeaturesPage />}
                    />
                    {userObject && (
                        <>
                            <Route
                                path="/rediger-profil"
                                exact={true}
                                element={<EditProfile />}
                            />
                            <Route path="/my-page" exact={true} element={<CustomerPage />} />
                            <Route
                                path="/bestilt-aktivitet"
                                exact={true}
                                element={<OrderedActivity />}
                            />
                        </>
                    )}
                </Routes>
                <Footer />
            </LoadingOverlay>
                </>


      
    );
}

export default App;
