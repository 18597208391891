import React, { useState } from "react";
import InnerHeader from "../InnerHeader/InnerHeader";
import SectionHeadingDesc from "../SectionHeadingDesc/SectionHeadingDesc";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import ThemeBtn from "../ThemeBtn/ThemeBtn";
import "./PartnersPage.scss";
import "swiper/css";
import "swiper/css/pagination";

import "./../ContactSection/ContactSection";

import Input from "../Input/Input";
import AboutRow from "../AboutRow/AboutRow";
import FetchService from "../../api/FetchService";
import { SelectUnstyled } from "@mui/base";

function PartnersPage() {
  let request = {
    company: "",
    email: "",
    body: "",
    subject: "",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    request.company = name;
    request.email = email;
    request.body = message;
    request.subject = reason;

    FetchService.SendEmailBecomePartner(request).then((response) => {
      if (response) {
        alert("Takk for at du kontaktet oss");
        setEmail("");
        setMessage("");
        setName("");
        setReason("");
      } else {
        alert("Feil. Vær så snill, prøv på nytt");
      }
    });
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");

    return (
        <div data-testid="wrapper" class="_loading_overlay_wrapper css-79elbk Contact_main">
          <div class="searchRsult_page">
            <div class="container">
              <div class="inner_header_main">
                <div class="header_wrapper">VIL DU BLI VÅR SAMARBEIDSPARTNER?</div>
              </div>
            </div>
            <div class="searchRsult_main">
              <div class="container">
                        <div class="wrapper">
                            <div className="row row_custom">
                               <div className="col-lg-6 col_content_otr partner-wrapper">

                              <p class="heading filter_heading">
                                Funtlers er på god vei til å bli en ledende sosial markedsplass i Norge, og vi tilbyr både fysiske og digitale aktiviteter. 
                                Ved å samarbeide med oss får du som aktivitetsleverandør tilgang til flere spennende muligheter:
                    </p>
                                <ul class="benefits-list">
                                    <li><p><strong>Unik eksponering:</strong> Øk synligheten til virksomheten din blant et bredt publikum.</p></li>
                                    <li><p><strong>Flere bookinger:</strong> Maksimer antall bookinger månedlig gjennom vårt nettverk.</p></li>
                                    <li><p><strong>Eksklusivt utvalg:</strong> Vær en del av vårt unike tilbud av sosiale aktiviteter.</p></li>
                                    <li><p><strong>Trofaste kunder:</strong> Tiltrekk deg tilbakevendende kunder som verdsetter kvalitet og fellesskap.</p></li>
                                </ul>

                              <p class="heading filter_heading">
                                Er du interessert? Fyll ut skjemaet, så tar vi kontakt for en uforpliktende samtale.
                                                    </p>
                                                </div>
                               <div className="col-lg-6 col_form_otr">
                                  <form className="col_form_inr" onSubmit={handleSubmit}>
                                    <div className="input_otr">
                                      <Input
                                        InputClass="Theme_input_white"
                                        Inputype="name"
                                        InputName="reason"
                                        InputPlaceholder="Kontaktperson"
                                        value={reason}
                                        onChange={(event) => setReason(event.target.value)}
                                      />
                                    </div>
                                    <div className="input_otr">
                                      <Input
                                        InputClass="Theme_input_white"
                                        Inputype="name"
                                        InputName="name"
                                        InputPlaceholder="Bedriftsnavn"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                      />
                                    </div>
                                    <div className="input_otr">
                                      <Input
                                        InputClass="Theme_input_white"
                                        Inputype="email"
                                        InputName="email"
                                        InputPlaceholder="E-post"
                                        value={email}
                                        onChange={(event) => setEmail(event.target.value)}
                                      />
                                    </div>

                                    <div className="input_otr">
                                      <textarea
                                        className="Theme_input_white Text_area"
                                        placeholder="Kommentar"
                                        value={message}
                                        onChange={(event) => setMessage(event.target.value)}
                                      />
                                    </div>
                                    <div className="action_otr">
                                      <ThemeBtn
                                        BtnClass="Theme_btn_primary send_btn"
                                        BtnText="Send"
                                      />
                                    </div>
                                  </form>
                                            </div>
                            </div>
                        </div>
              </div>
            </div>
          </div>
        </div>



    //<div className="partner_page">
    //  <InnerHeader HeaderHeading="Bli partner" PageText="Bli partner" />
    //  {/* //todo: replace with the new become partner page. currently replacing it temporarily until we have the new page. */}
    //  {/* <div className='partner_main'>
    //            <div className='container'>
    //                <SectionHeadingDesc
    //                    Heading="Our Partners"
    //                    Desc="The sources of ideas for software products are plentiful. These ideas can come from market research."
    //                />
    //                <div className='swiper_main'>
    //                    <Swiper
    //                        slidesPerView={5}
    //                        spaceBetween={24}
    //                        pagination={{
    //                            clickable: true,
    //                        }}
    //                        modules={[Pagination]}
    //                        breakpoints={{
    //                            0: {
    //                                slidesPerView: 2,
    //                            },
    //                            575: {
    //                                slidesPerView: 3,
    //                            },
    //                            768: {
    //                                slidesPerView: 3,
    //                            },
    //                            1024: {
    //                                slidesPerView: 5,
    //                            },
    //                        }}
    //                        className="mySwiper logo_swiper"
    //                    >
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo1} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo2} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo3} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo4} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo5} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo1} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo2} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo3} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                        <SwiperSlide>
    //                            <Link className='logo_otr'>
    //                                <img className='logo_img' src={PartnerLogo4} alt='img' />
    //                            </Link>
    //                        </SwiperSlide>
    //                    </Swiper>
    //                </div>
    //            </div>
    //        </div> */}
    //  <section className="Contact_main partner_contact">
    //    <div className="container">
    //      <div className="row row_custom">
    //        <div className="col-lg-6 col_content_otr">
    //          <div className="col_content_inr">
    //            <h2 className="heading-h22 mb-4">
    //              Hvorfor samarbeide med Funtlers
    //            </h2>

    //            <p className="heading-s desc">
    //            Funtlers er på god vei til å bli den nest største sosiale markedsplassen i Norge, og tilbyr fysiske og digitale aktiviteter.
    //            </p>
    //            <p className="heading-s desc">
    //              Hvorfor samarbeide med Funtlers?
    //            </p>
    //            <p className="heading-s desc">
    //              <ul className="ul-style">
    //                <li> Unik eksponeringsmulighet </li>
    //                <li>
    //                  Koble virksomheten din opp mot bredt publikum i hele Norge{" "}
    //                </li>
    //                <li>Maksimere antall bookinger månedlig</li>
    //                <li>
    //                  Vær endel av vårt unike utvalg av sosiale aktiviteter
    //                </li>
    //                <li>Trofaste og tilbakevendende kunder</li>
    //              </ul>
    //              <br />
    //              Er dette interessant?{" "}
    //            </p>
    //            <p className="heading-s desc">
    //            Fyll ut følgende skjema også tar vi kontakt med deg for en uforpliktende prat.{" "}
    //            </p>
    //          </div>
    //        </div>
    //        <div className="col-lg-6 col_form_otr">
    //          <form className="col_form_inr" onSubmit={handleSubmit}>
    //            <div className="input_otr">
    //              <Input
    //                InputClass="Theme_input_white"
    //                Inputype="name"
    //                InputName="reason"
    //                InputPlaceholder="Kontaktperson"
    //                value={reason}
    //                onChange={(event) => setReason(event.target.value)}
    //              />
    //            </div>
    //            <div className="input_otr">
    //              <Input
    //                InputClass="Theme_input_white"
    //                Inputype="name"
    //                InputName="name"
    //                InputPlaceholder="Bedriftsnavn"
    //                value={name}
    //                onChange={(event) => setName(event.target.value)}
    //              />
    //            </div>
    //            <div className="input_otr">
    //              <Input
    //                InputClass="Theme_input_white"
    //                Inputype="email"
    //                InputName="email"
    //                InputPlaceholder="E-post"
    //                value={email}
    //                onChange={(event) => setEmail(event.target.value)}
    //              />
    //            </div>

    //            <div className="input_otr">
    //              <textarea
    //                className="Theme_input_white Text_area"
    //                placeholder="Kommentar"
    //                value={message}
    //                onChange={(event) => setMessage(event.target.value)}
    //              />
    //            </div>
    //            <div className="action_otr">
    //              <ThemeBtn
    //                BtnClass="Theme_btn_primary send_btn"
    //                BtnText="Send"
    //              />
    //            </div>
    //          </form>
    //        </div>
    //      </div>
    //    </div>
    //  </section>
    //</div>
  );
}

export default PartnersPage;