import React from "react";
import FeatureCard from "../FeatureCard/FeatureCard";
import SectionHeadingDesc from "../SectionHeadingDesc/SectionHeadingDesc";



import CardImg1 from '../../img/card-shape-light.svg';
import CardImg2 from '../../img/card-shape-white.svg';

import "./FeaturesSection.scss";

function FeaturesSection() {
    return (
        <div data-testid="wrapper" class="_loading_overlay_wrapper css-79elbk">
            <div class="searchRsult_page">
                <div class="container">
                    <div class="inner_header_main">
                        <div class="header_wrapper">SLIK FUNGERER DET</div>
                    </div>
                </div>
                <div class="searchRsult_main">
                    <div class="container">
                        <div class="wrapper">
                            <div class="row row_custom">
                                <div class="col-lg-4 col-md-6 col-sm-6 col_card_otr">
                                    <div class="col_card_inr h-100">
                                        <div class="Icon_otr">
                                            <i class="ri-heart-fill Icon"></i>
                                            <i class="ri-heart-fill Icon2"></i>
                                        </div>
                                        <p class="heading-s card_tag"></p>
                                        <h3 class="heading-h3 card_heading">UTFORSK MULIGHETER</h3>
                                        <p class="heading-s card_desc">
                                            Bla gjennom vårt brede utvalg av aktiviteter som passer ulike interesser, grupper og anledninger. Finn noe spennende for enhver anledning.
                                        </p>
                                        <img
                                            class="card_img1 card_img"
                                            src={CardImg1}
                                            alt="img"
                                        />
                                        <img
                                            class="card_img2 card_img"
                                            src={CardImg2}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col_card_otr">
                                    <div class="col_card_inr h-100">
                                        <div class="Icon_otr">
                                            <i class="ri-heart-fill Icon"></i>
                                            <i class="ri-heart-fill Icon2"></i>
                                        </div>
                                        <p class="heading-s card_tag"></p>
                                        <h3 class="heading-h3 card_heading">BESTILL ENKELT</h3>
                                        <p class="heading-s card_desc">
                                            Velg ønsket aktivitet, og kjøp rabatten hos oss. Du får en unik kode på e-post, som brukes til å avtale tid direkte med leverandøren.
                                        </p>
                                        <img
                                            class="card_img1 card_img"
                                            src={CardImg1}
                                            alt="img"
                                        />
                                        <img
                                            class="card_img2 card_img"
                                            src={CardImg2}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6 col_card_otr">
                                    <div class="col_card_inr h-100">
                                        <div class="Icon_otr">
                                            <i class="ri-heart-fill Icon"></i>
                                            <i class="ri-heart-fill Icon2"></i>
                                        </div>
                                        <p class="heading-s card_tag"></p>
                                        <h3 class="heading-h3 card_heading">TID FOR OPPLEVELSER</h3>
                                        <p class="heading-s card_desc">
                                            Møt opp, delta og nyt en minneverdig opplevelse.
                                        </p>
                                        <img
                                            class="card_img1 card_img"
                                            src={CardImg1}
                                            alt="img"
                                        />
                                        <img
                                            class="card_img2 card_img"
                                            src={CardImg2}
                                            alt="img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




    //<section className="Features_main">
    //  <div className="container">
    //    <SectionHeadingDesc
    //      Heading="Slik fungerer det."
    //      Desc="Funtler drives av to gode venninner som tror på å skape lykke for deg selv og de man er glad i."
    //    />
    //    <div className="row row_custom">
    //      <FeatureCard
    //        IconClass="ri-heart-fill Icon"
    //        IconClass2="ri-heart-fill Icon2"
    //        CardHeading="Søk etter aktivitet"
    //        CardDesc="Se gjennom våre rabatterte forslag og les mer om de aktivitetene som virker interessante for deg."
    //      />
    //      <FeatureCard
    //        IconClass="ri-heart-fill Icon"
    //        IconClass2="ri-heart-fill Icon2"
    //        // CardTitle="Enjoy time"
    //        CardHeading="Kjøp aktivitet"
    //        CardDesc="Velg din aktivitet, og kjøp rabatten direkte hos oss. Du vil få en bookingbekreftelse på din mail med en unik kode fra Funtlers. Denne må du bruke for å booke dato direkte med aktivitetsleverandør. Steg til bestilling av tid ligger i bookingbekreftelsen. Koden fungerer som ditt betalingsbevis!"
    //      />
    //      <FeatureCard
    //        IconClass="ri-heart-fill Icon"
    //        IconClass2="ri-heart-fill Icon2"
    //        // CardTitle="Enjoy time"
    //        CardHeading="Gled deg"
    //        CardDesc="Nå er det bare å glede seg til enda et minne i opplevelsesbanken"
    //      />
    //    </div>
    //  </div>
    //</section>
  );
}

export default FeaturesSection;
