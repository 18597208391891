import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./DynamicSearchScreen.scss";
import DataRowActionManager from "../ChildComponent/DataRowActionManager/DataRowActionManager";
import APIServices from "../../../api/API-Services";
import { API } from "../../../api/API-Url";
import MyBootstrapTable from "../ChildComponent/MyBootstrapTable/MyBootstrapTable";

const DynamicSearchScreen = () => {
    const { screenName } = useParams();
    const [loader, setLoaderAfterDelay] = useState(true);
    const setloader = (value) => {
        if (value == false) {
            setTimeout(() => {
                setLoaderAfterDelay(false); // Hide loader after the specified delay
            }, 3100);

        }
        else {
            setLoaderAfterDelay(true); // Hide loader after the specified delay

        }


    };




    const [screen, setScreen] = useState({});
    const [data, setData] = useState([]);
    const [formSchema, setFormSchema] = useState([]);
    const [actions, setActions] = useState([]);
    const [formData, setFormData] = useState({});
    const [formfilters, setFormfilters] = useState([]);

    const [formfiltersSelected, setFormfiltersSelected] = useState([]);


    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc"); // or 'desc'
    const [rowLevelTesting, setRowLevelTesting] = useState(false);
    const [testData, setTestData] = useState({
        type: "",       // Stores the selected type (e.g., "static", "query", "api")
        inputQuery: "", // Stores the value of the input query
    });
    const [isTestEnabled, setIsTestEnabled] = useState(false); // Default is "off"
    const [testTypes, setTestTypes] = useState([]);



    const [htmlContent, sethtmlContent] = useState(null);

    const [currentAction, setcurrentAction] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);
    useEffect(() => {
        // Initialize the Google Translate widget after the script is loaded
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en", // Original language (English)
                    layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, // Simple layout
                    includedLanguages: "no", // Only Norwegian
                    autoDisplay: false, // Prevent automatic display of the widget
                },
                "google_translate_element"
            );

            // Set up a MutationObserver to wait for the language dropdown to be available
            const observer = new MutationObserver(() => {
                const langSelector = document.querySelector('.goog-te-combo');

                if (langSelector) {
                    // Find Norwegian option
                    const norwegianOption = Array.from(langSelector.options).find(
                        option => option.textContent.includes('Norwegian')
                    );

                    if (norwegianOption) {
                        langSelector.value = norwegianOption.value; // Select Norwegian option
                        langSelector.dispatchEvent(new Event('change')); // Trigger the change event
                        observer.disconnect(); // Stop observing once Norwegian is selected

                        // Hide the Google Translate widget by adding a class to the element
                        const translateDiv = document.getElementById('google_translate_element');
                        if (translateDiv) {
                            translateDiv.classList.add('hidden');
                        }
                    }
                }
            });

            // Start observing the body for changes to detect when the dropdown is added
            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        };

        // Add the Google Translate script dynamically
        const addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" // Ensure HTTPS
        );
        document.body.appendChild(addScript);

        addScript.onload = () => {
            console.log("Google Translate script loaded successfully.");
        };

        addScript.onerror = (error) => {
            console.error("Failed to load Google Translate script:", error);
        };

        window.googleTranslateElementInit = googleTranslateElementInit;

        // Cleanup: Remove the script if it's already in the DOM to avoid duplicate loading
        return () => {
            const scriptElement = document.querySelector('[src="https://translate.google.com/translate_a/element.js"]');
            if (scriptElement) {
                scriptElement.remove();
            }
        };
    }, []); // Empty dependency array ensures this runs only once on mount




    const handleMainBack = () => {
        const initialPath = window.location.pathname; // Save the current path
        const pollInterval = 100; // Time interval to check the location change

        const pollHistory = setInterval(() => {
            if (window.location.pathname !== initialPath) {
                clearInterval(pollHistory); // Stop polling when the path changes
            } else {
                window.history.back(); // Go back in history
            }
        }, pollInterval);
    };



    const handleBack = () => {
        sethtmlContent(null); // Clear the HTML viewer and go back
        setcurrentAction(null);
        handleResetChange();
    };


    const handleRefresh=() => {
        performAction();


    }



    useEffect(() => {
        fetchScreenData();
    }, [currentPage]);

    useEffect(() => {
        const elements = document.querySelectorAll(".content-wrapper");
        elements.forEach((el) => clampText(el, 3)); // Clamp to 3 lines
    }, []);


    const FilterCards = () => {
        return (
            <div className="filter-cards-container">
                {formfilters && Object.keys(formfilters).length > 0 ? (
                    Object.keys(formfilters).map((key) => {
                        const filter = formfilters[key];

                        // Check if filter has displayName and count
                        if (filter.displayName && filter.count !== undefined) {
                            return (
                                <div
                                    className={`filter-card ${formfiltersSelected && formfiltersSelected.id === filter.id ? 'selected' : 'non-selected'
                                        }`}
                                    key={key}
                                    onClick={() => {
                                        fetchScreenData(filter);
                                    }}
                                >
                                    <h3>{filter.displayName}</h3>
                                    <p>{filter.count} items</p>
                                </div>
                            );
                        }

                        return null; // If the filter doesn't have displayName or count, render nothing
                    })
                ) : (
                    <p></p>
                )}
            </div>
        );
    };



    function clampText(element, lineCount) {
        const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
        const maxHeight = lineCount * lineHeight;

        if (element.scrollHeight > maxHeight) {
            element.style.maxHeight = `${maxHeight}px`;
            element.style.overflow = 'hidden';
            element.style.textOverflow = 'ellipsis';
        }
    }


    const fetchScreenData = async (filter=null) => {
        try {
            setloader(true);
            const response = await APIServices.GET(
                `${API.url}dynamicSearch/${screenName}?search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&filterId=${filter ? filter.id : 0}`
            );


            setFormfiltersSelected(filter);
            setScreen(response.data.screen || {});
            setData([...response.data.data]);  // Trigger a new reference to force re-render


            setFormSchema(response.data.formSchema || []);
            setActions(response.data.actions || []);
            setTotalItems(response.data.totalCount || 0);
            setFormfilters(response.data.formfilters);
            setloader(false);


            // Check if any action has displayName "Test"
            const hasTestAction = response.data.actions.some(
                (action) => action.displayName?.toLowerCase() === "test"
            );

       

            setRowLevelTesting(hasTestAction); // Enable row-level testing if the "Test" action exists


        } catch (error) {
            console.error("Error fetching screen data:", error);
            setloader(false);

        }
    };

    const handleSearchChange = (e) => {
  
        setCurrentPage(1); // Reset to the first page on search
        fetchScreenData();
    };

    const handleResetChange = () => {

        setSearchQuery("");
        setFormData([]);
        setCurrentPage(1); // Reset to the first page on search
        fetchScreenData();
    };


    // Fetch data by Id for editing
    const fetchDataById = async (id, row) => {
        try {
            setloader(true);
            debugger;
            const response = await APIServices.POST(
                `${API.url}dynamicSearch/${screenName}/getByID?screenName=${screenName}&updatedrowid=${row[screen.primaryKey]}`, // Pass `id` as a query parameter
            );
            if (response.data) {
                setFormSchema(response.data);
                setFormData(row); // Populate form with fetched data
                setloader(false);
            }
        } catch (error) {
            console.error('Error fetching data for edit:', error);
            setloader(false);

        }
    };



    // Fetch data by Id for editing
    const fetchCutomAction = async (id, data) => {
        try {

            setloader(true);

            const response = await APIServices.POST(
                `${API.url}dynamicSearch/${screenName}/performAction?customActionID=${id}`, // Pass `id` as a query parameter
                data // Pass `row` as the request body
            );
            if (response.data) {
                if (data.CustomAction.actionType === "HTML-Viewer") {
                    // Dynamically set the HTML viewer content
                    sethtmlContent(response.data); // Safely insert the HTML content
                    setloader(false);

                    fetchDataById(data.SearchScreenSettings.id, data.Rowdata); // Fetch data to edit when edit is clicked


                }


                setloader(false);

            }
        } catch (error) {
            console.error('Error fetching data for edit:', error);
            setloader(false);

        }
    };



    const performAction = ([action, row] = []) =>
    {

        [action, row] = action ? [action, row] : [currentAction[0], currentAction[1]];

        let datajson = {
            "SearchScreenSettings": screen,
            "FormSchemas": formSchema,
            "CustomAction": action,
            "Rowdata": row

        }
        fetchCutomAction(action.id, datajson);
    }


    const handleAction = (action, row) => {
        debugger;

        // Normalize action names for consistent comparison
        const normalizedActionName = action.displayName?.toLowerCase(); // Normalize displayName
        const normalizedActionType = action.actionType?.toLowerCase(); // Normalize actionType

        if (normalizedActionType === "defaultaction") {
            if (normalizedActionName === "edit") {
                fetchDataById(screen.id, row); // Fetch data to edit when edit is clicked
            } else if (normalizedActionName === "delete") {
                handleDelete(row.Id); // Handle delete action
            }

            
        }
        else {
            setcurrentAction([action,row]);
            performAction([action, row]);
        }
    };



    const handleAddOrEdit = async () => {
        try {
            if (formData.Id) {
                // Edit functionality
                await APIServices.PUT(`${API.url}dynamicSearch/${screenName}/edit/${formData.Id}`, formData);
            } else {
                // Add functionality
                await APIServices.POST(`${API.url}dynamicSearch/${screenName}/add`, formData);
            }

            if (currentAction) {
                handleRefresh();
            }
            else {
                fetchScreenData(); // Refresh the data grid
                setFormData({}); // Clear the form
            }
           
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };




    //const handleAdd = async () => {
    //    try {
    //        await APIServices.POST(`${API.url}dynamicSearch/${screenName}/add`, formData);
    //        fetchScreenData();
    //        setFormData({});
    //    } catch (error) {
    //        console.error("Error adding data:", error);
    //    }
    //};

    const handleDelete = async (id) => {
        try {
            await APIServices.DELETE(`${API.url}dynamicSearch/${screenName}/delete/${id}`);
            fetchScreenData();
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



    // Sorting function
    const handleSort = (field) => {
        const newSortOrder = sortField === field && sortOrder === "asc" ? "desc" : "asc"; // Toggle sorting order
        setSortField(field);
        setSortOrder(newSortOrder);

        // Sorting the data
        const sortedData = [...data].sort((a, b) => {
            if (a[field] < b[field]) return sortOrder === "asc" ? -1 : 1;
            if (a[field] > b[field]) return sortOrder === "asc" ? 1 : -1;
            return 0;
        });

        setData(sortedData); // Set the sorted data
    };



    return (
        <div id="google_translate_element">
            {loader && <div className="loader"></div>}
           
            {screen.displayName && (
                <div className="dynamic-search-screen" style={{
                    visibility: loader ? 'hidden' : 'visible',  // If loader is true, hide; if false, show
                }}>
                    <div className="search-and-grid">
                        {/* Data Grid */}


                        {htmlContent ? (
                            <div className="data-grid" style={!(screen.isAdd || formData.Id) ? { width: '100%', padding: '52px' } : {}}
>
                                <button onClick={handleBack} style={{ marginBottom: "10px" }}>
                                    <i class="ri-arrow-left-line"></i>  Back
                                </button>
                                <button onClick={handleRefresh} style={{ marginBottom: "10px" }}>
                                    <i className="ri-refresh-line"></i> Refresh
                                </button>
                                <iframe
                                    srcDoc={htmlContent}  // Correct way to set srcdoc in JSX
                                    style={{ width: "100%", height: "80vh" }}  // Correct way to set style in JSX
                                />
                            </div>
                        ) : (
                        
                                <div className="data-grid" style={!(screen.isAdd || formData.Id) ? { width: '100%', padding: '52px' } : {}}>
                                    <button onClick={handleMainBack} onClick={handleMainBack}
                                        style={{
                                            marginBottom: "10px",
                                            backgroundColor: "transparent",
                                            color: "black",
                                            fontWeight: 500,
                                        }}>
                                        <i class="ri-arrow-left-line"></i>  Back
                                    </button>
                                    <div className="top-menu">
                                        <div>

                                            <div className="search-container">

                                                <h2>{screen.displayName || "Dynamic Screen"}</h2>

                                                {/*<label>Search:</label>*/}
                                                <div className="search-bar-wrapper">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter search query..."
                                                        value={searchQuery}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                        className="search-bar"
                                                    />
                                                    <button onClick={handleSearchChange} className="search-btn">
                                                        Search
                                                    </button>
                                                    <button onClick={handleResetChange} className="search-btn">
                                                        <i className="ri-restart-line utility-icon"></i>
                                                        Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {FilterCards()}
                                </div>
                                {/* Search */}
                                    <MyBootstrapTable
                                        data={data}
                                        formSchema={formSchema}
                                        actions={actions}
                                        handleAction={handleAction}
                                        screenName={screenName}
                                    />

                                {/*<table>*/}
                                {/*    <thead>*/}
                                {/*            <tr>*/}
                                {/*                <th className="checkbox-column">*/}
                                {/*                    <input*/}
                                {/*                        id="select-all-checkbox"*/}
                                {/*                        type="checkbox"*/}
                                {/*                        onChange={(e) => {*/}
                                {/*                            const { checked } = e.target;*/}

                                {/*                            // Loop through data and update isChecked property*/}
                                {/*                            const updatedData = data.map((row) => {*/}
                                {/*                                if (row.hasOwnProperty('isChecked')) {*/}
                                {/*                                    // Invert the value if isChecked exists*/}
                                {/*                                    row.isChecked = checked;*/}
                                {/*                                } else {*/}
                                {/*                                    // If isChecked doesn't exist, set it to true or false*/}
                                {/*                                    row.isChecked = checked;*/}
                                {/*                                }*/}
                                {/*                                return row;*/}
                                {/*                            });*/}

                                {/*                            // Update the selectedRows based on the updatedData*/}
                                {/*                            setSelectedRows(updatedData.filter((row) => row.isChecked));*/}
                                {/*                        }}*/}
                                {/*                        ref={(checkbox) => {*/}
                                {/*                            if (checkbox) {*/}
                                {/*                            }*/}
                                {/*                        }}*/}
                                {/*                    />*/}
                                {/*                </th>*/}


                                {/*            {formSchema.map((field) => (*/}
                                {/*                <th*/}
                                {/*                    key={field.id}*/}
                                {/*                    onClick={() => handleSort(field.fieldName)}*/}
                                {/*                    style={{ cursor: "pointer" }}*/}
                                {/*                >*/}
                                {/*                    {field.displayName}*/}
                                {/*                    {sortField === field.fieldName ? (*/}
                                {/*                        sortOrder === "asc" ? (*/}
                                {/*                            <i className="ri ri-arrow-up-fill utility-icon"></i>*/}
                                {/*                        ) : (*/}
                                {/*                            <i className="ri ri-arrow-down-fill utility-icon"></i>*/}
                                {/*                        )*/}
                                {/*                    ) : (*/}
                                {/*                        <i className="ri ri-arrow-down-fill utility-icon"></i>*/}
                                {/*                    )}*/}
                                {/*                </th>*/}
                                {/*            ))}*/}
                                {/*            <th className="center-align">Actions</th>*/}
                                {/*        </tr>*/}
                                {/*    </thead>*/}
                                {/*    <tbody>*/}
                                {/*        {data.length > 0 ? (*/}
                                {/*            data.map((row) => (*/}
                                {/*                <tr key={row.Id}>*/}
                                {/*                    <td>*/}
                                {/*                        <input*/}
                                {/*                            type="checkbox"*/}
                                {/*                            className="row-checkbox"*/}
                                {/*                            checked={row.isChecked || false} // Bind the checkbox state to row.isChecked*/}
                                {/*                            onChange={(e) => {*/}
                                {/*                                // Update the row's isChecked property when the checkbox is clicked*/}
                                {/*                                row.isChecked = e.target.checked;*/}
                                {/*                                // Update selectedRows state based on the updated isChecked value*/}
                                {/*                                setSelectedRows(data.filter((r) => r.isChecked)); // Set selectedRows to the ones with isChecked = true*/}
                                {/*                            }}*/}
                                {/*                        />*/}
                                {/*                    </td>*/}
                                {/*                    {formSchema.map((field) => (*/}
                                {/*                        <td key={field.id}>*/}
                                {/*                            <div*/}
                                {/*                                className="content-wrapper"*/}
                                {/*                                title={(() => {*/}
                                {/*                                    const content =*/}
                                {/*                                        field.fieldName === "ParentId" && row.ParentId*/}
                                {/*                                            ? field.options.find(*/}
                                {/*                                                (opt) => opt.value === row.ParentId*/}
                                {/*                                            )?.text || "N/A"*/}
                                {/*                                            : row[field.fieldName] || "N/A";*/}

                                {/*                                    return content.length > 200 ? content : ""; // Show full text as tooltip if too long*/}
                                {/*                                })()}*/}
                                {/*                                ref={(el) => {*/}
                                {/*                                    if (el && el.scrollHeight > el.clientHeight) {*/}
                                {/*                                        el.classList.add("truncated");*/}
                                {/*                                    }*/}
                                {/*                                }}*/}
                                {/*                            >*/}
                                {/*                                {field.fieldName === "ParentId" && row.ParentId*/}
                                {/*                                    ? field.options.find((opt) => opt.value === row.ParentId)?.text ||*/}
                                {/*                                    "N/A"*/}
                                {/*                                    : row[field.fieldName] || "N/A"}*/}
                                {/*                            </div>*/}
                                {/*                        </td>*/}
                                {/*                    ))}*/}

                                {/*                    <td>*/}
                                {/*                        <div className="action-css">*/}
                                {/*                            {actions.map((action) => {*/}
                                {/*                                // Check if the action is 'edit' and if screen is in edit mode*/}
                                {/*                                if (action.displayName.toLowerCase() === "edit" && screen.isEdit) {*/}
                                {/*                                    return (*/}
                                {/*                                        <button*/}
                                {/*                                            key={action.id}*/}
                                {/*                                            onClick={() => handleAction(action, row)}*/}
                                {/*                                            className="action-btn"*/}
                                {/*                                        >*/}
                                {/*                                            <i className={`ri ${action.icon} utility-icon`}></i>*/}
                                {/*                                            {action.displayName}*/}
                                {/*                                        </button>*/}
                                {/*                                    );*/}
                                {/*                                }*/}

                                {/*                                // Check if the action is 'delete' and if screen allows delete*/}
                                {/*                               else if (action.displayName.toLowerCase() === "delete" && screen.isDelete) {*/}
                                {/*                                    return (*/}
                                {/*                                        <button*/}
                                {/*                                            key={action.id}*/}
                                {/*                                            onClick={() => handleAction(action, row)}*/}
                                {/*                                            className="action-btn"*/}
                                {/*                                        >*/}
                                {/*                                            <i className={`ri ${action.icon} utility-icon`}></i>*/}
                                {/*                                            {action.displayName}*/}
                                {/*                                        </button>*/}
                                {/*                                    );*/}
                                {/*                                }*/}

                                {/*                               else if (action.displayName.toLowerCase() != "delete" || action.displayName.toLowerCase() != "edit") {*/}
                                {/*                                    return (*/}
                                {/*                                        <button*/}
                                {/*                                            key={action.id}*/}
                                {/*                                            onClick={() => handleAction(action, row)}*/}
                                {/*                                            className="action-btn"*/}
                                {/*                                        >*/}
                                {/*                                            <i className={`ri ${action.icon} utility-icon`}></i>*/}
                                {/*                                            {action.displayName}*/}
                                {/*                                        </button>*/}
                                {/*                                    );*/}
                                {/*                                }*/}

                                {/*                                // Return nothing if neither condition is met*/}
                                {/*                            })}*/}
                                {/*                        </div>*/}

                                {/*                    </td>*/}
                                {/*                </tr>*/}
                                {/*            ))*/}
                                {/*        ) : (*/}
                                {/*            <tr>*/}
                                {/*                <td colSpan={formSchema.length + 1} className="empty-row">*/}
                                {/*                    No data available*/}
                                {/*                </td>*/}
                                {/*            </tr>*/}
                                {/*        )}*/}
                                {/*    </tbody>*/}
                                {/*</table>*/}

                                {/* Pagination */}
                                {/*<div className="pagination">*/}
                                {/*    <button*/}
                                {/*        onClick={() => handlePageChange(currentPage - 1)}*/}
                                {/*        disabled={currentPage === 1}*/}
                                {/*        className="page-btn"*/}
                                {/*    >*/}
                                {/*        Previous*/}
                                {/*    </button>*/}
                                {/*    {Array.from({ length: Math.ceil(totalItems / pageSize) }).map((_, index) => (*/}
                                {/*        <button*/}
                                {/*            key={index}*/}
                                {/*            onClick={() => handlePageChange(index + 1)}*/}
                                {/*            className={currentPage === index + 1 ? "active page-btn" : "page-btn"}*/}
                                {/*        >*/}
                                {/*            {index + 1}*/}
                                {/*        </button>*/}
                                {/*    ))}*/}
                                {/*    <button*/}
                                {/*        onClick={() => handlePageChange(currentPage + 1)}*/}
                                {/*        disabled={currentPage === Math.ceil(totalItems / pageSize)}*/}
                                {/*        className="page-btn"*/}
                                {/*    >*/}
                                {/*        Next*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>)}

                        {/* Form */}
                        {(screen.isAdd || formData.Id) &&
                            <div className="search-form">
                                <div className="form">
                                    <h2>{formData.Id ? "Edit " : "Add"}&nbsp;{screen.displayName}</h2>
                                    <form>
                                        {formSchema.map((field) => (
                                            <div key={field.id}>
                                                <label>
                                                    {field.isRequired && <span className="text-red-500">*</span>}
                                                    {field.displayName}
                                                </label>
                                                {field.fieldType === "textarea" ? (
                                                    <textarea
                                                        required={field.isRequired}
                                                        value={formData[field.fieldName] || ""}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                [field.fieldName]: e.target.value,
                                                            })
                                                        }
                                                    />
                                                ) : field.fieldType === "select" ? (
                                                    <select
                                                        required={field.isRequired}
                                                        value={formData[field.fieldName] || ""}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                [field.fieldName]: e.target.value,
                                                            })
                                                        }
                                                    >
                                                        <option value="">Select an option</option>
                                                        {field.options?.map((option) => (
                                                            <option key={option.value} value={option.value}>
                                                                {option.text}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type={field.fieldType || "text"}
                                                        required={field.isRequired}
                                                        value={formData[field.fieldName] || ""}
                                                        onChange={(e) =>
                                                            setFormData({
                                                                ...formData,
                                                                [field.fieldName]: e.target.value,
                                                            })
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ))}

                                        <DataRowActionManager screenName={screenName} />

                                        {rowLevelTesting && (
                                            <>
                                                <div className="toggle-container">
                                                    <label>Enable Custom Action</label>
                                                    <label className="toggle-switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={isTestEnabled}
                                                            onChange={(e) => setIsTestEnabled(e.target.checked)}
                                                        />
                                                        <span className="slider"></span>
                                                    </label>
                                                </div>

                                               {isTestEnabled && (<DataRowActionManager screenName={screenName} />)}
                                            </>
                                        )}

                                    </form>
                                    <button onClick={handleAddOrEdit} className="add-btn">
                                        {formData.Id ? "Update" : "Add"}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )}
          




          
        </div>
    );
};

export default DynamicSearchScreen;
